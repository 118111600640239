/* eslint-disable react/no-unknown-property */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/NotificationWindow/NotificationWindow" />
import * as React from "react";
import { CopilotImage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/images/CopilotImage";
import { 
    Button,
    LoaderIcon,
    ModalDialog,
    Text,
    IconDesignEyeslashRegular,
    IconArrowsChevronDownRegular,
    LinkButton 
} from '@medius/ui-controls';
import { IconActionCloseRegular } from '@medius/ui-controls';
import { CopilotButton } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { HideDuration, IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { useClickOutsideReference } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useClickOutsideReference";
import { getIsNotificationWindowVisible } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { MediusCopilotInteractionType, trackInteractionEvent } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights";
import { copilotEvents } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";

interface IProps{
    predefinedQuestions: IPredefinedQuestion[];
    taskId: number;
}

interface IOptionsMenuProps{
    hideWindow: () => void;
    closeCallback: () => void;
    reference: React.MutableRefObject<any>;
    taskId: number;
}

export const NotificationWindow = ({ predefinedQuestions, taskId }: IProps) => {
    const [isHidden, setIsHidden] = React.useState(!getIsNotificationWindowVisible());
    const [isOptionsModalHidden, setIsOptionsModalHidden] = React.useState(true);

    const optionsMenuReference = React.useRef(null);
    useClickOutsideReference(optionsMenuReference, () => { setIsOptionsModalHidden(true); });

    React.useEffect(() => {       
        const handleMessage = (event: MessageEvent) => {
            const { type } = event.data;
        
            if (type === copilotEvents.HideNotificationWindow) {            
                setIsHidden(true);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    React.useEffect(() => {
        if(!isHidden){
            trackInteractionEvent(MediusCopilotInteractionType.OpenNotification);
        }
        
    }, [isHidden]);

    const hideWindow = () => {
        setIsHidden(true);
    };

    const closeWindow = () => {
        hideWindow();
        trackInteractionEvent(MediusCopilotInteractionType.CloseNotification, taskId);
    };

    const dispatchEvent = (questionId: number, questionTag: string) => {
        hideWindow();
        window.postMessage({ type: copilotEvents.PredefinedQuestionFromNotificationWindow, payload: { questionId: questionId } }, '*');
        trackInteractionEvent(MediusCopilotInteractionType.NotificationWindowButtonClick, taskId, questionTag);
    };

    const questionButtons = predefinedQuestions.map((question, index) => (
        <CopilotButton 
            key={index} 
            isMainCopilotButton={false} 
            data-testid={`notification-window-question-button-${question.categorizationId}`}	 
            label={getLabelTranslation(question.value)} 
            onClick={() => dispatchEvent(question.categorizationId, question.value)} 
        />
    ));

    return (
        <>{
        !(isHidden || predefinedQuestions.length === 0) && 
        <div className="copilotNotificationWindow__anchor">
            <div className="copilotNotificationWindow__leftInnerAnchor">
                <div className="copilotNotificationWindow__mainContainer">
                    <div className="copilotNotificationWindow__topRow">
                        <div className="copilotNotificationWindow__topRow__titleContainer">
                            <div className="copilotNotificationWindow__topRow__imageContainer">
                                <CopilotImage />
                            </div>
                            <p data-testid="notification-window-title">
                                {getLabelTranslation("#Core/letCopilotHelpYou")}
                            </p>
                        </div>
                        <div className="copilotNotificationWindow__topRow__buttonsContainer">
                            <div 
                                className="copilotNotificationWindow__topRow__button" 
                                onClick={closeWindow} 
                                data-testid="close-notification-window-button"
                            >
                                <IconActionCloseRegular size="medium"/>
                            </div>
                        </div>
                    </div>
                    <div className="copilotNotificationWindow__buttonsContainer">
                    { questionButtons.length === 0 ? 
                        <div className="copilotNotificationWindow__buttonsContainer__loaderContainer">
                            <LoaderIcon /> 
                        </div>
                        : questionButtons 
                    }
                    </div>
                    <div className="copilotNotificationWindow__bottomRow">
                        <div className="copilotNotificationWindow__bottomRow__optionsMenu">
                            <div className="copilotNotificationWindow__optionsMenuAnchor">
                                { !isOptionsModalHidden && 
                                    <OptionsMenu 
                                        hideWindow={hideWindow}
                                        closeCallback={() => setIsOptionsModalHidden(true)} 
                                        reference={optionsMenuReference} 
                                        taskId={taskId}
                                    /> 
                                }
                            </div>
                            <div 
                                className="copilotNotificationWindow__bottomRow__button options" 
                                onClick={() => setIsOptionsModalHidden(false)}
                                style={isOptionsModalHidden ? {} : { backgroundColor: "#E7F0F8" }}
                                data-testid="options-menu-button"
                            >
                                <IconDesignEyeslashRegular size="small" color="brand"/>
                                <p>{getLabelTranslation("#Core/hide")}</p>
                                <IconArrowsChevronDownRegular size="small" color="brand"/>
                            </div>
                        </div>
                        <LinkButton
                            isTrustedUrl
                            label={getLabelTranslation("#Core/learnMore")}
                            url={`${appConfig.getDocumentationUrl()}_medius_copilot`}
                        />
                    </div>
                </div>
            </div>
        </div>
        }</>
    );
};

function OptionsMenu({ hideWindow, closeCallback, reference, taskId }: IOptionsMenuProps){
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
    const [selectedDuration, setSelectedDuration] = React.useState<HideDuration | null>(null);

    const openConfirmation = (timespan: HideDuration) => {
        if(isConfirmationDialogOpen)
            return;

        setSelectedDuration(timespan);
        setIsConfirmationDialogOpen(true);
    };

    const setHideTimespan = (onlyForToday: boolean = false) => {        
        const hideDuration = new Date();

        if(onlyForToday){
            hideDuration.setHours(23,59,0,0); //until the end of this day
            trackInteractionEvent(MediusCopilotInteractionType.NotificationWindowHideoutReason, taskId, "onlyForToday");
        } else {
            switch (selectedDuration) {
                case "day":
                    hideDuration.setHours(23,59,0,0);
                    break;
                case "week":
                    hideDuration.setDate(hideDuration.getDate() + 7);
                    break;
                case "month":
                    hideDuration.setMonth(hideDuration.getMonth() + 1);
                    break;
                case "forever":
                    hideDuration.setFullYear(9999, 1, 1);
                    break;
                default:
                    throw new Error("[NotificationWindow.tsx] incorrect time duration selected");
            }
            trackInteractionEvent(MediusCopilotInteractionType.NotificationWindowHideoutReason, taskId, selectedDuration);
        }

        localStorage.setItem("copilotNotificationWindowHideDuration", hideDuration.toISOString());
        hideWindow();
    };

    const resolveTimeDurationText = () => {
        if(!selectedDuration)
            return "";
        switch(selectedDuration){
            case "month":
                return getLabelTranslation("#Core/aMonth");
            case "week":
                return getLabelTranslation("#Core/aWeek");
            case "day":
                return getLabelTranslation("#Core/today");
            case "forever":
                return getLabelTranslation("#Core/forever");
        }
    };

    return (
        <div className="copilotNotificationWindow__optionsMenuContainer" ref={reference}>
            <ModalDialog
                isOpen={isConfirmationDialogOpen}
                onRequestClose={() => false}
                title={getLabelTranslation("#Core/areYouSureYouWanToCloseCopilotTips")}
                showHeader={false}
                width="small"
                renderContent={() => (
                    <div close-ai-agent-modal="false">
                        <Text variant="title" close-ai-agent-modal="false">{getLabelTranslation("#Core/areYouSureYouWanToCloseCopilotTips")}</Text>
                        {
                            selectedDuration !== "forever" ?
                                <Text variant="paragraph" close-ai-agent-modal="false">{getLabelTranslation("#Core/wontHaveAccessToCopilotTipsForXTime", resolveTimeDurationText())}</Text>
                                :
                                <Text variant="paragraph" close-ai-agent-modal="false">{getLabelTranslation("#Core/wontHaveAccessToCopilotTipsAgain")}</Text>
                        }
                        <div className="copilotNotificationWindow__confirmationDialogContainer__buttonsContainer" close-ai-agent-modal="false">
                            { selectedDuration !== "day" && 
                                <Button 
                                    label={getLabelTranslation("#Core/hideForTodayInstead")} 
                                    onClick={() => setHideTimespan(true)} 
                                    variant="secondary" close-ai-agent-modal="false"
                                    data-testid="notification-window-hide-for-today-instead"
                                />
                            }
                            <div className="copilotNotificationWindow__confirmationDialogContainer__buttonsContainer__rightSide" close-ai-agent-modal="false">
                                <Button 
                                    label={getLabelTranslation("#Core/no")} 
                                    variant="secondary" 
                                    onClick={closeCallback} 
                                    close-ai-agent-modal="false" 
                                    data-testid="notification-window-hide-cancel"
                                />
                                <Button 
                                    label={getLabelTranslation("#Core/yesHide")} 
                                    onClick={() => setHideTimespan()} 
                                    close-ai-agent-modal="false" 
                                    data-testid="notification-window-hide-confirm"
                                />
                            </div>
                        </div>
                    </div>
                )}
            />
            <button 
                disabled={isConfirmationDialogOpen} 
                style={isConfirmationDialogOpen ? { cursor: "not-allowed" } : null}
                onClick={() => openConfirmation("day")}
                data-testid="notification-window-hide-for-today-button"
            >
                {getLabelTranslation("#Core/stopShowingMediusCopilotTipsForToday")}
            </button>
            <button 
                disabled={isConfirmationDialogOpen} 
                style={isConfirmationDialogOpen ? { cursor: "not-allowed" } : null}
                onClick={() => openConfirmation("week")}
                data-testid="notification-window-hide-for-week-button"
            >
                {getLabelTranslation("#Core/doNotShowMediusCopilotTipsForTheNext7Days")}
            </button>
            <button 
                disabled={isConfirmationDialogOpen} 
                style={isConfirmationDialogOpen ? { cursor: "not-allowed" } : null}
                onClick={() => openConfirmation("month")}
                data-testid="notification-window-hide-for-month-button"
            >
                {getLabelTranslation("#Core/doNotShowMediusCopilotTipsForTheNextMonth")}
            </button>
            <button 
                disabled={isConfirmationDialogOpen} 
                style={isConfirmationDialogOpen ? { cursor: "not-allowed" } : null}
                onClick={() => openConfirmation("forever")}
                data-testid="notification-window-hide-for-forever-button"
            >
                {getLabelTranslation("#Core/doNotShowMediusCopilotTipsAgain")}
            </button>
        </div>
    );
}