/* eslint-disable react/no-unknown-property */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/InvoiceImageTooltip/InvoiceImageTooltip" />
import * as React from "react";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { CopilotButton } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton";
import { IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { LoaderIcon, IconActionCloseRegular } from '@medius/ui-controls';
import { MediusCopilotInteractionType, trackInteractionEvent } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights";
import { copilotEvents } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { getIsInvoiceImageTooltipVisible } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";

interface IProps{
    predefinedQuestions: IPredefinedQuestion[];
}

export function InvoiceImageTooltip({predefinedQuestions}: IProps) {
    const [isHidden, setIsHidden] = React.useState(!getIsInvoiceImageTooltipVisible());

    const dispatchEvent = (questionId: number, questionTag : string) => {
        window.postMessage({ type: copilotEvents.PredefinedQuestionFromInvoiceImageTooltip, payload: { questionId: questionId } }, '*');
        trackInteractionEvent(MediusCopilotInteractionType.InvoiceImageTooltipButtonClick, null, questionTag);
    };

    const closeTooltip = () => {
        sessionStorage.setItem("isCopilotInvoiceImageTooltipHidden", "closed");
        setIsHidden(true);
    };

    const questionButtons = predefinedQuestions.map((question, index) => (
        <CopilotButton
            key={index}
            close-ai-agent-modal="false"
            isMainCopilotButton={true} 
            data-testid="" 
            label={getLabelTranslation(question.value === "#Core/SummarizeTheInvoiceContext" ? "#Core/copilotSummarize" : "#Core/copilotTranslate")} 
            onClick={() => dispatchEvent(question.categorizationId, question.value)}
        />
    ));

    return (
        <>{!isHidden && 
            <div className="copilotInvoiceImageTooltip__anchor" close-ai-agent-modal="false">
                <div className="copilotInvoiceImageTooltip__mainContainer" close-ai-agent-modal="false">
                    <div className="copilotInvoiceImageTooltip__buttonsContainer" close-ai-agent-modal="false">
                        { questionButtons.length === 0 ?
                            <div className="copilotInvoiceImageTooltip__buttonsContainer__loaderContainer" close-ai-agent-modal="false">
                                <LoaderIcon close-ai-agent-modal="false"/> 
                            </div>
                            : questionButtons 
                        }
                        <div className="copilotInvoiceImageTooltip__closeButton" onClick={() => closeTooltip()}>
                            <IconActionCloseRegular size="small" close-ai-agent-modal="false"/>
                        </div>
                    </div>
                </div>
            </div>
        }</>
    );
}