///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/InvoiceInsightsWidget/NextApproverErrorMessage"/>
import { 
    FlexLayout, 
    Text 
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";

export const NextApproverErrorMessage = () => {
    return (
        <FlexLayout
            direction="column"
            width="fill"
            className="mediusAgent__nextApproverErrorMessageContainer"
        >
            <Text variant="body">
                <span className="mediusAgent__nextApproverErrorMessageContainer__header">
                    {getLabelTranslation("#Core/weAreUnableToRetrieveTheApproverDetailsAtTheMoment")}&nbsp;
                </span>
                {getLabelTranslation("#Core/youCanStillProceedWithTheInvoice")}
            </Text>
        </FlexLayout>
    );
};
