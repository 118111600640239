/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfFile"/>
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
export class PdfFile {
    private documentType: string;
    private documentId: number;
    private hashFileObj: any;

    constructor(documentId: number, documentType: string, hashFile: any) {
        this.hashFileObj = hashFile;
        this.documentType = documentType;
        this.documentId = documentId;
    }

    public docType() { return this.documentType; }
    public docId() { return this.documentId; }
    public hashFile() { return this.hashFileObj; }

    public pdfViewerUrl() {
        const hash = this.hashFileObj.Hash();
        const documentType = this.documentType;
        const pdfUrl = encodeURIComponent(`${getBasePath()}Backend/Rest/MediaService/image/${hash}/pdf?docId=${this.documentId}&docType=${documentType
            }&tag=DocumentImage&download=application/pdf;base64`);

        return `${getBasePath()}Content/packages/core/${appConfig.getApplicationVersion()}/libs/pdf.js/${appConfig.getPdfJsVersion()}/web/viewer.html?file=${pdfUrl}`;
    }
}
