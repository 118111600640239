/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoGrid" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import { KendoGridCollapseExpand } from "Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoGridCollapseExpand";
import { MultiEventHandler } from "Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/multiEventHandler";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as ko from "knockout";
import * as $ from "jquery";

const columnDefaultWidth = 125;

class KendoGridBinding {
    private static readonly widgetName = "kendoGrid";

    private static rebindGrid($div: JQuery, params: any): void {
        const options = ko.unwrap(params.options) as KendoGridOptions;

        const currentGrid = $div.data(KendoGridBinding.widgetName);
        if (currentGrid) {
            currentGrid.destroy();
        }

        $div.empty();

        if (options) {
            KendoGridBinding.setupColumnMenuInitWithRemove(options);
            KendoGridCollapseExpand.setupButtons(options);
            KendoGridBinding.setupCommands(options);
            KendoGridBinding.setupClickableRows(options);
            KendoGridBinding.setupRowClick(options);
            KendoGridBinding.setupDestroy(options);

            const grid = KendoGridBinding.createGridWidget($div, options);
            KendoGridBinding.createJqueryRowClick(options, grid);
            KendoGridBinding.setupAddingColumnMethods(options, grid);
            KendoGridBinding.setupExportToExcel(options, grid);
            KendoGridBinding.setupGetColumns(options, grid);
            KendoGridBinding.setupColumnsTooltip(options, grid);
            KendoGridBinding.setupReplaceCurrentNoRecordsMessageContent(options, grid);
            KendoGridBinding.createContextMenu(options, grid);

            options.setGridPagerMessage = (text: string) => {
                const message = grid.pager.element.find("#grid-pager-message");
                if (message.length === 0) {
                    grid.pager.element.append(`<span id="grid-pager-message">${text}</span>`);
                } else {
                    message.text(text);
                }
            };
        }
    }

    private static setupDestroy(gridOptions: KendoGridOptions) {
        gridOptions.dispose = function ({ options, element }: { options: any; element: Element }) {
            if (options.detachClickAction) {
                options.detachClickAction();
            }
            if (options.contextMenuElement) {
                options.contextMenuElement.data("kendoContextMenu").destroy();
            }
        };
    }

    private static setupReplaceCurrentNoRecordsMessageContent(gridOptions: KendoGridOptions, grid: kendo.ui.Grid) {
        gridOptions.replaceCurrentNoRecordsMessageContent = (content: string) => {
            const noRecords = grid.element.find(".k-grid-norecords");
            if (!noRecords.length)
                logger.error(
                    "there is no 'No-Record' message currently displayed by Kendo Grid."
                    + " the `replaceCurrentNoRecordsMessageContent` method"
                    + " should not be called, as it will not have any effect!");

            noRecords.html(content);
        };
    }

    private static createContextMenu(gridOptions: KendoGridOptions, grid: kendo.ui.Grid) {
        if (gridOptions.contextMenu) {
            const contextMenuElement = $(gridOptions.contextMenu.templateId).clone();
            contextMenuElement.kendoContextMenu({
                target: grid.element,
                filter: "tr[role='row'].clickable",
                select: gridOptions.contextMenu.getAction(grid)
            });
            (grid.options as any).contextMenuElement = contextMenuElement;
        }
    }

    private static setupRowClick(gridOptions: KendoGridOptions) {
        if (gridOptions.rowClick) {
            gridOptions.change = MultiEventHandler.from(
                gridOptions.change,
                arg => {
                    const grid = arg.sender;
                    const row = grid.dataItem(grid.select());
                    gridOptions.rowClick(row);
                });
        }
    }

    private static createJqueryRowClick(gridOptions: KendoGridOptions, grid: kendo.ui.Grid) {
        if (gridOptions.jqueryRowClick) {
            gridOptions.jqueryRowClick(grid);
        }
    }

    private static setupClickableRows(gridOptions: KendoGridOptions) {
        if (gridOptions.rowClick || gridOptions.jqueryRowClick) {
            gridOptions.dataBound = MultiEventHandler.from(
                gridOptions.dataBound,
                e => {
                    const rows = e.sender.tbody.find("tr[data-uid]");
                    for (let j = 0; j < rows.length; j++) {
                        const row = $(rows[j]);
                        row.addClass("clickable");
                    }
                });
        }
    }

    private static setupCommands(gridOptions: KendoGridOptions) {
        gridOptions.columns.forEach(c => {
            if (!c.command) {
                return;
            }

            const commands: any[] = Array.isArray(c.command)
                ? c.command
                : [c.command];

            commands.forEach(cmd => {
                if (typeof cmd === "string") {
                    return;
                }

                const command = cmd as kendo.ui.GridColumnCommandItem;
                if (typeof command.click !== "function") {
                    return;
                }

                // When command is not built-in one, lets fix handler:
                const customHandler = command.click as ((item: kendo.data.Model, grid: kendo.ui.Grid) => void);
                command.click = function (e: JQueryEventObject) {
                    e.preventDefault();

                    // ReSharper disable once SuspiciousThisUsage
                    const grid = this as kendo.ui.Grid;
                    const row = $(e.currentTarget).closest("tr");
                    const dataItem = grid.dataItem(row);

                    // Note: When provided `command` is named same as built-in, you might get unexpected behaviour.
                    //       Please avoid names: 'edit', 'destroy', also 'delete' will cause problems (Kendo's bug).
                    customHandler(dataItem as kendo.data.Model, grid);
                };
            });
        });
    }

    private static createGridWidget($div: JQuery, options: KendoGridOptions) {
        const instance = $div.kendoGrid(options).data(KendoGridBinding.widgetName);
        KendoUtils.registerDisposeFor($div, KendoGridBinding.widgetName);

        return instance;
    }

    private static setupAddingColumnMethods(gridOptions: KendoGridOptions, grid: kendo.ui.Grid) {
        gridOptions.addColumn = (field: string) => {
            const column = grid.columns.filter(col => col.field === field)[0];
            if (column.width === undefined || column.width === null || column.width === "auto") {
                column.width = columnDefaultWidth;
            }

            grid.reorderColumn(grid.columns.filter(col => !col.hidden).length, column);
            grid.showColumn(field);
        };

        gridOptions.removeColumn = (field: string) => {
            const column = grid.columns.filter(col => col.field === field)[0];
            grid.hideColumn(field);
            grid.reorderColumn(grid.columns.length - 1, column);
            gridOptions.onRemovingColumn(field);

            const sortConfig = grid.dataSource.sort();
            const fixedSortConfig = sortConfig.filter(x => x.field !== column.field);
            if (sortConfig.length != fixedSortConfig.length) {
                // Note: calling `dataSource.sort` as a setter, will trigger rather unnecessary
                //       data request - when grid does not have any rows currently. and really,
                //       there is no any scenario, where hiding a column, especially not used
                //       for sorting nor grouping, would need rows to be reloaded.
                grid.dataSource.sort(fixedSortConfig);
            }
        };
    }

    private static setupExportToExcel(gridOptions: KendoGridOptions, grid: kendo.ui.Grid) {
        gridOptions.onExportingToExcel = () => grid.saveAsExcel();
    }

    private static setupGetColumns(gridOptions: KendoGridOptions, grid: kendo.ui.Grid): void {
        gridOptions.getColumns = (): Column[] => {
            const dataSource: kendo.data.DataSource = gridOptions.dataSource;
            const groupConfig: kendo.data.DataSourceGroupItem[] = dataSource.group();
            const sortConfig = dataSource.sort();

            return grid.columns.filter(col => !col.hidden).map(
                (col: kendo.ui.GridColumn, i: number) => ({
                    field: col.field,
                    position: i,
                    grouping: KendoGridBinding.getColumnGrouping(groupConfig, col.field),
                    sorting: KendoGridBinding.getColumnSorting(sortConfig, col.field),
                    width: KendoGridBinding.getColumnWidth(groupConfig, i, grid)
                }));
        };
    }

    private static setupColumnsTooltip(gridOptions: KendoGridOptions, grid: kendo.ui.Grid): void {
        if (!gridOptions.columnsTooltipEnabled) {
            return;
        }

        grid.thead.find("th:not(.k-group-cell)").each(
            (idx: number, element: Element) => {
                const header = $(element);
                header.attr("title", header.attr("data-title"));
            });
    }

    private static getColumnGrouping(groupConfig: kendo.data.DataSourceGroupItem[], field: string): ColumnGrouping {
        if (groupConfig) {
            const group = groupConfig.filter(s => s.field === field)[0];
            if (group) {
                return {
                    position: groupConfig.indexOf(group),
                    sortDirection: group.dir
                };
            }
        }
        return null;
    }

    private static getColumnSorting(sortConfig: kendo.data.DataSourceSortItem[], field: string): ColumnSorting {
        if (sortConfig) {
            const sort = sortConfig.filter(s => s.field === field)[0];
            if (sort) {
                return {
                    position: sortConfig.indexOf(sort),
                    direction: sort.dir
                };
            }
        }
        return null;
    }

    private static getColumnWidth(groupConfig: any[], cellIndex: number, grid: kendo.ui.Grid): number {
        if (!groupConfig) {
            return columnDefaultWidth;
        }

        const headers = grid.thead[0] as HTMLTableSectionElement;
        const header = (headers.rows[0] as any).cells[cellIndex + groupConfig.length];
        return header.offsetWidth;
    }

    private static setupColumnMenuInitWithRemove(gridOptions: KendoGridOptions): void {
        if (!gridOptions.removeColumnEnabled) {
            return;
        }

        gridOptions.columnMenuInit = MultiEventHandler.from(
            gridOptions.columnMenuInit,
            (event: kendo.ui.GridColumnMenuInitEvent) => {
                const grid = event.sender;
                if (grid.columns.filter(col => !col.hidden).length < 2) {
                    return;
                }

                const menuName = getLabelTranslation("#Core/remove");
                const popup = event.container.data("kendoPopup");
                const menu = event.container.find(".k-menu").data("kendoMenu");
                const field = event.field;

                menu.append([{ text: menuName, spriteCssClass: "k-font-icon k-i-delete" }]);
                menu.bind(
                    "select",
                    (e: any) => {
                        if ($(e.item).text() === menuName) {
                            gridOptions.removeColumn(field);
                            popup.close();
                        }
                    });
            });
    }

    public static registerKnockoutBindings(): void {
        registerBinding(
            KendoGridBinding.widgetName,
            {
                update(element: HTMLElement, valueAccessor: any) {
                    const $element = $(element);

                    KendoUtils.ensureValidElement(KendoGridBinding.widgetName, "div", $element);
                    KendoGridBinding.rebindGrid($element, valueAccessor());
                }
            });
    }
}

export function register() {
    KendoGridBinding.registerKnockoutBindings();
}
