/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taxEngine/addressInformation"/>

import { Card, FixedGridLayout, LocalizationContext, StringMap, Text, Button, IconActionAddRegular, FlexLayout, FlexSpacer, ContentDivider, IconActionDeleteRegular, DropDownField, TextField, ViewField } from "@medius/ui-controls";
import * as React from "react";
import { getCultureCode } from "../../Medius/lib/utils/user";
import { mapFromNorthstarToApa } from "../../lib/northstarLocalization";
import { translate } from "../../lib/globalization";

export interface AddressInformationProps {
    addressInformations: AddressInformationData[],
    isEditable: boolean,
    onAddressInformationChange: ({type, addressInformation}: { type: AddressActionType, addressInformation: AddressInformationData }) => void
}

export interface AddressInformationData {
    id: number,
    tempId: number,
    type: string,
    street: string,
    state: string,
    city: string,
    postalCode: string,
    country: string
}

export interface EditableAddressInformationLine {
    addressInformation: AddressInformationData,
    onAddressInformationChange: ({type, addressInformation}: { type: AddressActionType, addressInformation: AddressInformationData }) => void
    getValidationErrors: (type: string) => string[]
}

export const dataTestIds = {
    addressInformationStreetText(ordinal: number) { return `address-information-street-text_${ordinal}`; },
    addressInformationCityText(ordinal: number) { return `address-information-city-text_${ordinal}`; },
    addressInformationStateText(ordinal: number) { return `address-information-state-text_${ordinal}`; },
    addressInformationPostalCodeText(ordinal: number) { return `address-information-postal-code-text_${ordinal}`; },
    addressInformationCountryText(ordinal: number) { return `address-information-country-text_${ordinal}`; },
    addressInformationStreetInput(ordinal: number) { return `address-information-street-input_${ordinal}`; },
    addressInformationCityInput(ordinal: number) { return `address-information-city-input_${ordinal}`; },
    addressInformationStateInput(ordinal: number) { return `address-information-state-input_${ordinal}`; },
    addressInformationPostalCodeInput(ordinal: number) { return `address-information-postal-code-input_${ordinal}`; },
    addressInformationCountryInput(ordinal: number) { return `address-information-country-input_${ordinal}`; },
    addressInformationTypeInput(ordinal: number) { return `address-information-type-input_${ordinal}`; },
    addressInformationTypeText(ordinal: number) { return `address-information-type-text_${ordinal}`; },
    addAddressButton: 'address-informations-add-address-button',
    deleteAddressButton(ordinal: number) { return `address-informations-delete-address-button_${ordinal}`; },
    addressInformationsCard : 'address-informations-card'
};

const validAddressTypes = [
    { text: 'Ship from', value: 'ShipFrom' },
    { text: 'Ship to', value: 'ShipTo' },
];

export enum AddressActionType {
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update'
}

const EditableAddressInformationLineComponent = (addressInformationLine: EditableAddressInformationLine) => {
    const addressInformationData = addressInformationLine.addressInformation;
    const dispatchAddressInformationChange = addressInformationLine.onAddressInformationChange;
    const getAddressTypesList = () => validAddressTypes.some(v => v.value === addressInformationData.type) ? 
        validAddressTypes : 
        [{ text: addressInformationData.type, value: addressInformationData.type }, ...validAddressTypes];
    
    return (
        <>
            <FlexLayout>
                <DropDownField
                    fieldLabel={translate('#PurchaseToPay/addressType')}
                    data-testid={dataTestIds.addressInformationTypeInput(addressInformationData.tempId)}
                    isRequired={true}
                    errorMessages={addressInformationLine.getValidationErrors(addressInformationData.type)}
                    inputProps={{
                        useStringValue: true,
                        allItems: getAddressTypesList(),
                        value: addressInformationData.type,
                        onChange: ({
                            value }: any) => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, type: value } })
                        }}
                        />
                <FlexSpacer />
                <Button
                    data-testid={dataTestIds.deleteAddressButton(addressInformationData.tempId)}
                    icon={<IconActionDeleteRegular color="error" />}
                    label={translate('#PurchaseToPay/remove')}
                    variant="dangerInline"
                    onClick={() => { dispatchAddressInformationChange({ type: AddressActionType.DELETE, addressInformation: addressInformationData }); }}
                    />
            </FlexLayout>
            <FixedGridLayout columns={5} >
                <>
                    <TextField fieldLabel={translate('#PurchaseToPay/street')} data-testid={dataTestIds.addressInformationStreetInput(addressInformationData.tempId)}
                        inputProps={{
                            value: addressInformationData.street,
                            onChange: e => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, street: e.value } })
                        }}
                        />
                    <TextField fieldLabel={translate('#PurchaseToPay/city')} data-testid={dataTestIds.addressInformationCityInput(addressInformationData.tempId)}
                        inputProps={{
                            value: addressInformationData.city,
                            onChange: e => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, city: e.value } })
                        }}
                    />
                    <TextField fieldLabel={translate('#PurchaseToPay/state')} data-testid={dataTestIds.addressInformationStateInput(addressInformationData.tempId)}
                        inputProps={{
                            value: addressInformationData.state,
                            onChange: e => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, state: e.value } })
                        }}
                    />
                    <TextField fieldLabel={translate('#PurchaseToPay/postalCode')} data-testid={dataTestIds.addressInformationPostalCodeInput(addressInformationData.tempId)}
                        inputProps={{
                            value: addressInformationData.postalCode,
                            onChange: e => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, postalCode: e.value } })
                        }}
                    />
                    <TextField fieldLabel={translate('#PurchaseToPay/country')} data-testid={dataTestIds.addressInformationCountryInput(addressInformationData.tempId)}
                        inputProps={{
                            value: addressInformationData.country,
                            onChange: e => dispatchAddressInformationChange({ type: AddressActionType.UPDATE, addressInformation: { ...addressInformationData, country: e.value } })
                        }}
                    />
                </>
            </FixedGridLayout>
        </>
    );
};

const ViewableAddressInformationLineComponent = (addressInformationLine: AddressInformationData) => {
    const getAddressType = () => validAddressTypes.some(v => v.value === addressInformationLine.type) ? 
        validAddressTypes.find(v => v.value === addressInformationLine.type).text : 
        addressInformationLine.type;
    
    return (
        <>
            <Text data-testid={dataTestIds.addressInformationTypeText(addressInformationLine.tempId)} variant="heading3">{getAddressType()}</Text>
            <FixedGridLayout columns={5} >
                <ViewField fieldLabel={translate('#PurchaseToPay/street')} valueText={addressInformationLine.street} data-testid={dataTestIds.addressInformationStreetText(addressInformationLine.tempId)} />
                <ViewField fieldLabel={translate('#PurchaseToPay/city')} valueText={addressInformationLine.city} data-testid={dataTestIds.addressInformationCityText(addressInformationLine.tempId)} />
                <ViewField fieldLabel={translate('#PurchaseToPay/state')} valueText={addressInformationLine.state} data-testid={dataTestIds.addressInformationStateText(addressInformationLine.tempId)} />
                <ViewField fieldLabel={translate('#PurchaseToPay/postalCode')} valueText={addressInformationLine.postalCode} data-testid={dataTestIds.addressInformationPostalCodeText(addressInformationLine.tempId)} />
                <ViewField fieldLabel={translate('#PurchaseToPay/country')} valueText={addressInformationLine.country} data-testid={dataTestIds.addressInformationCountryText(addressInformationLine.tempId)} />
            </FixedGridLayout>
        </>
    );
};

export default function AddressInformationsComponent({ addressInformations, isEditable, onAddressInformationChange }: AddressInformationProps) {
    const getAddressValdiationErrors = (addressType: string) => {
        const errors = [];
        if (!validAddressTypes.some(v => v.value === addressType))
            errors.push(translate('#PurchaseToPay/invalidAddressType'));
        const count = addressInformations.filter(a => a.type === addressType).length;
        if (count > 1)
            errors.push(translate('#PurchaseToPay/addressTypeAlreadyExists'));

        return errors;
    };

    return (
        <div className="accordion-group">
            <LocalizationContext.Provider value={{
                translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
                getLocale: () => getCultureCode()
            }} >                
                <Card data-testid={dataTestIds.addressInformationsCard}
                    title={translate('#PurchaseToPay/deliveryAddresses')}
                    isCollapsible={true}
                    defaultOpen={addressInformations.length !== 0}
                    headerHeight="small">
                    {addressInformations.map((a) => (
                        <div key={a.tempId}>
                            {isEditable ?
                                <EditableAddressInformationLineComponent
                                    addressInformation={a}
                                    getValidationErrors={getAddressValdiationErrors}
                                    onAddressInformationChange={onAddressInformationChange}
                                    {...a}
                                />
                                :
                                <ViewableAddressInformationLineComponent
                                    {...a}
                                />
                            }
                            <ContentDivider addBottomSpacer={true} />
                        </div>
                    ))}
                    {isEditable &&
                        <Button
                            data-testid={dataTestIds.addAddressButton}
                            icon={<IconActionAddRegular />}
                            label={translate('#PurchaseToPay/addAddress')}
                            variant="inline"
                            onClick={() => {
                                onAddressInformationChange({
                                    type: AddressActionType.ADD,
                                    addressInformation: null
                                });
                            }}
                        />
                    }
                </Card>
            </LocalizationContext.Provider>
        </div>
    );
}

