/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/LoginOption/General/protocolType"/>
import {getLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export enum ProtocolType {
    WsTrust = 0,
    WsFed = 1,
    Google = 2,
    OpenIdConnect = 3
}

export class ProtocolTypeWithLabel {
    value: number;
    label: string;
    constructor(value: number, label: string) {
        this.value = value;
        this.label = label;
    }
}

export function getProtocolTypesWithLabels(): ProtocolTypeWithLabel[] {
    return [
        new ProtocolTypeWithLabel(ProtocolType.WsTrust, ProtocolType[ProtocolType.WsTrust]),
        new ProtocolTypeWithLabel(ProtocolType.WsFed, ProtocolType[ProtocolType.WsFed]),
        new ProtocolTypeWithLabel(ProtocolType.Google, ProtocolType[ProtocolType.Google]),
        new ProtocolTypeWithLabel(ProtocolType.OpenIdConnect, ProtocolType[ProtocolType.OpenIdConnect])
    ];
}