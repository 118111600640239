/// <amd-module name="Core/Medius.Core.Web/Scripts/appConfig"/>
import {
    applicationVersion,
    pdfJsVersion,
    installedAppsHash,
    documentationUrl,
    advancedSearchOperatorsDocumentationUrl,
    releaseNotesUrl,
    benchmarksUrl,
    privacyPolicyUrl,
    switchProviderUrl,
    logOffUrl,
    userVoiceEnabled,
    qaEnvironmentNoteVisible,
    currentAppDisplayVersion,
    deploymentName,
    applicationInsightsInstrumentationKey,
    applicationInsightsSamplingPercentage,
    ccfBaseUrl,
    ccfHost,
    lockManagerRepeatTime,
    lockManagerStartingAquiresNumber,
    supportPortalUrl,
    homePageSurveyUrl,
    cfoDashboardDocumentationUrl,
    payAppUrl,
    simAppUrl,
    analyticsAppUrl,
    isAccessManagementEnabled,
    loginApiUrl,
    MigratedRpcEndpointsList,
    MigratedWebApiEndpointsList,
    CanduToken
} from "Core/Medius.Core.Web/Scripts/appConfigData";
import { log, error } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

const mfaAppUrl = "mfaAppUrl";

const appConfig: { [index: string]: any } = {};

export function register(key: string, value: any) {
    appConfig[key] = value;
}

export function registerMany(settings: { key: string, value: any }[]) {
    settings.map(setting => register(setting.key, setting.value));
}

export function registerManyWithDictionary(settings: { [key: string]: any }) {
    for (const [key, value] of Object.entries(settings)) {
        register(key, value);
    }
}

function getValue(key: string): any {
    const value = appConfig[key];

    if (value == null) {
        throw Error(`Cannot find value for ${key}.`);
    }

    return value;
}

function getValueOrDefault(key: string): any {
    const value = appConfig[key];

    if (value == null) {
        return "";
    }
    return value;
}

export function getApplicationVersion(): string {
    return getValue(applicationVersion);
}

export function getPdfJsVersion(): string {
    return getValue(pdfJsVersion);
}

export function getInstalledAppsHash(): string {
    return getValue(installedAppsHash);
}

export function getDocumentationUrl(): string {
    return getValue(documentationUrl);
}

export function getPrivacyPolicyUrl(): string {
    return getValue(privacyPolicyUrl);
}

export function getAdvancedSearchOperatorsDocumentationUrl(): string {
    return getValue(advancedSearchOperatorsDocumentationUrl);
}

export function getReleaseNotesUrl(): string {
    return getValue(releaseNotesUrl);
}

export function getBenchmarksUrl(): string {
    return getValue(benchmarksUrl);
}

export function getSupportPortalUrl(): string {
    return getValue(supportPortalUrl);
}

export function getSwitchProviderUrl(): string {
    return getValue(switchProviderUrl);
}

export function getLogOffUrl(): string {
    return getValue(logOffUrl);
}

export function isTenantManagerOnly(): boolean {
    function hasPurchaseToPay() {
        const pattern = new RegExp(/(^|;)PurchaseToPay\/\d+/, "i");
        return pattern.test(appConfig[installedAppsHash]);
    }

    return !hasPurchaseToPay();
}

export function isUserVoiceEnabled(): boolean {
    return getValue(userVoiceEnabled) === "True";
}

export function isQaEnvironmentNoteVisible(): boolean {
    return getValue(qaEnvironmentNoteVisible) === "True";
}

export function getCurrentAppDisplayVersion(): string {
    return getValue(currentAppDisplayVersion);
}

export function getDeploymentName(): string {
    return getValue(deploymentName);
}

export function getApplicationInsightsInstrumentationKey(): string {
    return getValue(applicationInsightsInstrumentationKey);
}

export function getApplicationInsightsSamplingPercentage(): string {
    return getValue(applicationInsightsSamplingPercentage);
}

export function getCcfBaseUrl(): string {
    return getValue(ccfBaseUrl);
}

export function getCcfHost(): string {
    return getValue(ccfHost);
}

export function getLockManagerStartingAquiresNumber(): number {
    return parseInt(getValue(lockManagerStartingAquiresNumber), 10);
}

export function getLockManagerRepeatTime(): number {
    return parseInt(getValue(lockManagerRepeatTime), 10);
}

export function logInstalledApplicationsHash() {
    const apps = appConfig[installedAppsHash];
    if (apps) {
        log("Currently installed applications: " + apps);
    } else {
        error("Did not register currently installed applications hash.");
    }
}

export function getHomePageSurveyUrl(): string {
    return getValue(homePageSurveyUrl);
}

export function getCfoDashboardDocumentationUrl(): string {
    return getValue(cfoDashboardDocumentationUrl);
}

export function getPayUrl(): string {
    return getValue(payAppUrl);
}

export function getSIMUrl(): string {
    return getValue(simAppUrl);
}

export function getAnalyticsUrl(): string {
    return getValue(analyticsAppUrl);
}

export function getLoginApiUrl(): string {
    return getValue(loginApiUrl);
}

export function getIsAccessManagementEnabled(): boolean {
    return getValue(isAccessManagementEnabled);
}

export function getMfaAppUrl(): string {
    return getValue(mfaAppUrl);
}

export function getMigratedRpcEndpointsList(): string[] {
    const migratedEndpointsList = getValueOrDefault(MigratedRpcEndpointsList) as string;
    return migratedEndpointsList.split(",");
}

export function getEnabledMigratedWebApiEndpointsList(): MigratedWebApiEndpoint[] {
    const migratedEndpointsList = getValueOrDefault(MigratedWebApiEndpointsList) as string;
    return  migratedEndpointsList ? migratedEndpointsList.split(',')
        .map(entry=>entry.replace(/\s/g, ''))
        .filter(isNotEmpty)
        .map(toMigratedWebApiEndpoint): 
        [];
}

function toMigratedWebApiEndpoint(endpointWithVersion:string):MigratedWebApiEndpoint{
    const [endpoint, version] = endpointWithVersion.split("#");
    return {
        endpoint,
        version
    };
}

function isNotEmpty(s:string){
    return s!==null && s!==undefined && s!='';
}

export function getCanduToken(): string | null {
    return getValueOrDefault(CanduToken) as string | null;
}

export interface MigratedWebApiEndpoint{
    endpoint: string,
    version?: string
}