/// <amd-module name="Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepayments"/>
import * as React from "react";
import { useState } from 'react';
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { LocalizationContext, IconSystemSettingsFill, IconUserUserFill, TableIcon, StringMap, IconActionEditRegular, IconActionDeleteFill, IconActionAddRegular, Button, Card, ColumnDefinitionSet, DataTable, Notification, IconSecurityLockFill } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { PrepaymentInformation, OriginInvoiceData, localizeAmount, localizeDate } from "Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepaymentsCommon";
import { ApplyPrepaymentsModal } from "Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepaymentsModal";
import { trigger } from "Core/Medius.Core.Web/Scripts/eventBus";
import { taskReloadRequested } from "Core/Medius.Core.Web/Scripts/events/eventNames";

type PrepaymentInformationsProps = {
    prepaymentInformations: PrepaymentInformation[],
    originInvoiceData: OriginInvoiceData,
    shouldAllowPrepaymentApplication: boolean
};

interface PrepaymentsCardGridProps {
    prepaymentInformations: PrepaymentInformation[],
    shouldShowActionButtons: boolean
}

const PrepaymentsCardGrid = ({prepaymentInformations, shouldShowActionButtons}: PrepaymentsCardGridProps) => {
    const showApplicationsGrid = prepaymentInformations.length > 0;
    const columnDefinition: ColumnDefinitionSet<PrepaymentInformation> = [
        {
            columnType: "customContent",
            key: "InvoiceNumber",
            displayName: translate("#Enterprise/LBL_INVOICE_NUMBER"),
            overflowType: "truncate",
            renderRowContent: (item: PrepaymentInformation) => {       
                return (<div className="linkCell">
                {
                    item.CanAccessInvoice ? <a data-testid="prepayment-link" href={`${getBasePath()}#/Tasks/ShowDocument/${item.InvoiceId}`}>{item.InvoiceNumber}</a> : <span className="linkCell_text">{item.InvoiceNumber}</span>
                }
                {
                    item.CanAccessInvoice ? undefined : <div data-testid="insufficient-permission-to-open-prepayment-icon" title={translate('#PurchaseToPay/insufficientPermissionsToOpenTheInvoice')} className="linkCell_locker"><IconSecurityLockFill /></div>
                }
                </div>);
            },
            minColumnSize: 1
        },
        {
            columnType: "customContent",
            key: "IsManual",
            displayName: "",
            overflowType: "truncate",
            renderRowContent: (item) => {
                return <div className="oveflow-ellipsis-container">{item.IsManual ? <TableIcon icon={<IconUserUserFill data-testid="icon-contact" />} label={translate("#PurchaseToPay/prepayments/connectionCreatedByUser")} data-testid="prepayment-manual-icon"/>
                : <TableIcon icon={<IconSystemSettingsFill data-testid="icon-settings" />} label={translate("#PurchaseToPay/prepayments/connectionCreatedBySystem" )} data-testid="prepayment-auto-icon"/>}</div>;
            },
            horizontalAlignment : 'left',
            minColumnSize: 1,
            maxColumnSize: "content"
        },
        {
            columnType: "customContent",
            key: "InvoiceDate",
            displayName: translate("#Enterprise/LBL_INVOICE_DATE"),
            overflowType: "truncate",
            renderRowContent: (item: PrepaymentInformation) => {
                return <div data-testid="prepayment-date" className="oveflow-ellipsis-container">{localizeDate(item.InvoiceDate)}</div>;
            },
            minColumnSize: 1,
        },
        {
            key: "VoucherNumber",
            dataKey: "VoucherNumber",
            displayName: translate('#Enterprise/LBL_FINAL_VOUCHER_NUMBER'),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false,
            overflowType: "truncate",
            minColumnSize: 1,
        },
        {
            key: "PurchaseOrderNumber",
            dataKey: "PurchaseOrderNumber",
            displayName: translate("#Enterprise/orderNumber"),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false,
            overflowType: "truncate",
            minColumnSize: 1,
        },
        {
            columnType: "customContent",
            key: "AppliedAmount",
            displayName: translate("#PurchaseToPay/appliedAmount"),
            overflowType: "truncate",
            renderRowContent: (item: PrepaymentInformation) => {
                return <div data-testid="prepayment-applied-amount" className="oveflow-ellipsis-container">{localizeAmount(item.AppliedAmount)}</div>;
            },
            minColumnSize: 1,
            maxColumnSize: 2
        },
        {
            columnType: "actions",
            key: "actionIcons",
            displayName: String(),
            minColumnSize: 1,
            renderRowActions: () => {
                return <>
                    {
                        shouldShowActionButtons ? 
                            <div className= "action-buttons-container">
                                <Button data-testid='edit-button' label={translate('#PurchaseToPay/reporting/Edit')} hideLabel icon={<IconActionEditRegular />} variant="inline" />
                                <Button data-testid='delete-button' label={translate('#PurchaseToPay/reporting/delete')} hideLabel icon={<IconActionDeleteFill />} variant="inline" />
                            </div>
                        :   null
                    }
                </>;
            }
        }
    ];

    return <>
        {!showApplicationsGrid && 
            <div className="no-connections-notification">
                <Notification feedbackType="info" text={translate('#PurchaseToPay/noConnectionsNotification')}/>
            </div>}

        {showApplicationsGrid && <div className="applied-prepayments-information-table__container">
            <div className= "apply-prepayment-button">
                <Button data-testid ="apply-button" label={translate("#PurchaseToPay/appliedAmount")} icon={<IconActionAddRegular />} variant="inline"></Button>
            </div>
            <DataTable<PrepaymentInformation>
                label= {translate('#PurchaseToPay/prepaymentsLabel')}
                allData={prepaymentInformations}
                dataMethod="all"
                columns={columnDefinition}
                enableResizing={true}
                variant="compact"
                maxLinesPerRow={1}
                paging={{}}
                sorting={{
                    enableSorting: false
                }}
            />
        </div>}
    </>;
};

export const AppliedPrepayments = ({prepaymentInformations, originInvoiceData, shouldAllowPrepaymentApplication}: PrepaymentInformationsProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [prepaymentApplications, setPrepaymentApplications] = useState(prepaymentInformations);
    return <>
        <div className="accordion-group prepayment-card">
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <IntlProvider locale={getCurrentCulture()}>
                    <LocalizationProvider language="current-language">
                    <Card data-testid = "prepayment-card"
                        title={translate('#PurchaseToPay/prepaymentsLabel')} 
                        isCollapsible={true}
                        defaultOpen={prepaymentInformations.length !== 0}
                        headerHeight="small">
                        <>
                            <div>
                                    {shouldAllowPrepaymentApplication && <div className="prepayment-card__apply-prepayments-button"><Button data-testid="apply-prepayment-button" label={`+ ${translate("#PurchaseToPay/applyPrepayment")}`} variant="inline" onClick={() => setModalVisible(true)}/></div>}
                                <ApplyPrepaymentsModal prepaymentInformations={prepaymentApplications} originInvoiceData={originInvoiceData} modalVisible={modalVisible} onModalClose={(isApply: boolean, result: PrepaymentInformation[]) => {
                                    setModalVisible(false);
                                    if (!isApply) {
                                        return;
                                    }

                                    setPrepaymentApplications(result);
                                    trigger(taskReloadRequested, originInvoiceData.TaskId);
                                }} />
                            </div>
                            <PrepaymentsCardGrid prepaymentInformations={prepaymentApplications} shouldShowActionButtons={shouldAllowPrepaymentApplication}/>
                        </>
                    </Card>
                    </LocalizationProvider>
                </IntlProvider>
            </LocalizationContext.Provider>
        </div>
    </>;
};
