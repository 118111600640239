///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotContextProvider" />
import * as React from "react";
import { CopilotEventPayload, IMessage, IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";

export const defaultContext: ICopilotContext = {
    workflowStepName: "",
    invoiceType: "",
    documentId: 0,
    isOperational: false,
    predefinedQuestions: [],
    copilotEventPayload: null,
    setCopilotEventPayload: () => {},
    messages: [],
    setMessages: () => {},
    processedMessage: null, 
    setProcessedMessage: () => {},
    isStreamActive: false,
    setIsStreamActive: () => {},
    requestTimeout: null,
    setRequestTimeout: () => {},
    abortController: null,
    setAbortController: () => {},
    isAgentAvailable: true,
    setIsAgentAvailable: () => {},
    isLoadingMessages: true,
    setIsLoadingMessages: () => {},
    conversationId: "",
    taskId: 0,
    isDocumentImport: false
};

export const CopilotContext = React.createContext(defaultContext);

export interface ICopilotContext{
    workflowStepName: string;
    invoiceType: string;
    documentId: number;
    isOperational: boolean;
    predefinedQuestions: IPredefinedQuestion[];
    copilotEventPayload: CopilotEventPayload | null;
    setCopilotEventPayload: React.Dispatch<React.SetStateAction<CopilotEventPayload>>;
    messages: IMessage[];
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
    processedMessage: IMessage;
    setProcessedMessage: React.Dispatch<React.SetStateAction<IMessage>>;
    isStreamActive: boolean;
    setIsStreamActive: React.Dispatch<React.SetStateAction<boolean>>;
    requestTimeout: NodeJS.Timeout,
    setRequestTimeout: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>;
    abortController: AbortController;
    setAbortController: React.Dispatch<React.SetStateAction<AbortController>>;
    isAgentAvailable: boolean;
    setIsAgentAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    isLoadingMessages: boolean;
    setIsLoadingMessages: React.Dispatch<React.SetStateAction<boolean>>;
    conversationId: string;
    taskId: number;
    isDocumentImport: boolean;
}

interface ICopilotContextProviderProps extends ICopilotContext {
    children: React.ReactNode;
}

export function CopilotContextProvider({children, ...props}: ICopilotContextProviderProps){
    return (
        <CopilotContext.Provider value={props}>
            {children}
        </CopilotContext.Provider>
    );
}