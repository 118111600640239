/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/Addresses/newAddressManagementEmptyState"/>
import { Button, EmptyState, FlexLayout, ImageRocketRocketlaunch } from "@medius/ui-controls";
import { navigateTo } from "Core/Medius.Core.Web/Scripts/components/quickSearch/services/windowService";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as React from "react";

export const NewAddressManagementEmptyState = () => {
    return <FlexLayout width="fill" height="fill" verticalAlignment="center" horizontalAlignment="center">
        <EmptyState
            image={<ImageRocketRocketlaunch />} 
            title={translate("#Procurement/address/oldAddressViewEmptyState/title")} 
            size={"large"}
            renderActions={() => <Button 
                onClick={() => navigateTo(`${getBasePath()}#/configuration/AddressManagement`)}
                label={translate("#Procurement/address/oldAddressViewEmptyState/link")} 
                />}
            explanation={translate("#Procurement/address/oldAddressViewEmptyState/explanation")} 
            centerInContainer
            contentAlign="center" />
    </FlexLayout>;
};