/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider"/>
import { filter, indexOf } from "underscore";
import * as $ from "jquery";
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

export class DataProvider {

    filter(results: any, filterFunction: any) {
        const selectedItem = results.SelectedItemIndex && results[results.SelectedItemIndex],
            filteredResults = filter(results.Results, filterFunction),
            index = indexOf(filteredResults, selectedItem) || null;

        return {
            Results: filteredResults,
            SelectedItemIndex: index
        };
    }

    getId(entity: any) {
        entity = unpack(entity);
        if (!entity) {
            return null;
        }

        return unpack(entity.Id) || null;
    }

    search(phrase: string, maxResults:any = undefined, offset?:number, entityType:any = undefined): JQueryDeferred<any> | JQueryPromise<any> | any[] {
        return $.Deferred().resolve([]);
    }
    
    exact() {
        return this.search.apply(this, arguments);
    }
    createDummy(): any {
        return null;
    }
}