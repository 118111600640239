/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/dimensionLimitGrid"/>
import * as React from "react";
import { useCallback, DependencyList, useState, useRef } from "react";
import { Grid, GridCellProps, GridColumn, GridHeaderCellProps, GridSortChangeEvent, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { CheckboxCell, ButtonCell, InputCell, LockedDropDownCell, LimitTypeCell, LimitValueCell } from "./gridCells";
import { DimensionValueExpression, DimensionValuePermission, GetEmptyDimensionValuePermission, Dimension as DimensionType, LimitType } from "../../authorizationGroup";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { ValidationType } from "../../limit";
import { ExceptionsPopupCell } from "./exceptionsPopupCell";
import { process, SortDescriptor } from "@progress/kendo-data-query";
import DimensionColumnMenuSort from "./DimensionColumnMenuSort";
import { CheckboxHeaderCell, TooltipProps } from "./headerCells";
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon } from "@progress/kendo-svg-icons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";

type DimensionLimitGridProps = {
    readonly dimensions: DimensionType[];
    readonly dimensionLimits: DimensionValuePermission[];
    readonly onChange: (newPermissions: DimensionValuePermission[]) => void;
    readonly sort: SortDescriptor[];
    readonly onSortingChange: (newSort: SortDescriptor[]) => void;
};

const Dimension = "Dimension";
const Value = "Value";
const Description = "Description";
const HasCodingRight = "HasCodingRight";
const HasAuthorizationRight = "HasAuthorizationRight";
const DebitLimit = "DebitLimit";
const Exceptions = "Exceptions";
const CreditLimit = "CreditLimit";

const DimensionDataQa = "dimension-name";
const ValueDataQa = "dimension-limit-value";
const DescriptionDataQa = "dimension-limit-description";
const DebitLimitValueDataQa = "dimension-limit-debitLimit-value";
const DebitLimitTypeDataQa = "dimension-limit-debitLimit-type";
const CreditLimitValueDataQa = "dimension-limit-creditLimit-value";
const CreditLimitTypeDataQa = "dimension-limit-creditLimit-type";
const HasCodingRightDataQa = "dimension-limit-has-coding-right";
const HasAuthorizationRightDataQa = "dimension-limit-has-authorization-right";

enum ActionType { Add, Edit, Delete, ToggleAll }

type Property =
    "Dimension" | "Value" | "Description" | "Exceptions" | "DebitLimit" | "CreditLimit" | "HasCodingRight" | "HasAuthorizationRight";
type AddAction = { type: ActionType.Add, defaultDimension: DimensionType };
type EditAction = {
    type: ActionType.Edit
    property: Property
    rowId: number
    newValue: any
};
type DeleteAction = { type: ActionType.Delete; rowId: number };
type ToggleAllAction = {
    type: ActionType.ToggleAll
    property: Property
    newValue: boolean | LimitType
};

type DispatchAction = AddAction | EditAction | DeleteAction | ToggleAllAction;

interface PageState {
    skip: number;
    take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const toPerm = (dataItem: any) =>
    dataItem as DimensionValuePermission;

type RowData = DimensionValuePermission & { rowId: number };

function getNewValue(newValue: boolean | LimitType, propertyToChange: any) {
    if (typeof newValue === "boolean") {
        propertyToChange = newValue;
    } else {
        propertyToChange.Type = newValue;
    }
    return propertyToChange;
}

const dimensionLimitGridReducer = (state: RowData[], action: DispatchAction, onChange: (newPermissions: DimensionValuePermission[]) => void) => {

    switch (action.type) {
        case ActionType.Edit:
            onChange(state.map((x, idx) => idx === action.rowId ? { ...x, [action.property]: action.newValue } : x));
            break;
        case ActionType.Add:
            onChange(state.concat({ ...GetEmptyDimensionValuePermission(action.defaultDimension), rowId: state.length }));
            break;
        case ActionType.Delete:
            onChange(state.filter(x => x.rowId !== action.rowId));
            break;
        case ActionType.ToggleAll:
            onChange(state.map(x => {
                return { ...x, [action.property]: getNewValue(action.newValue, x[action.property] as any) };
            }));
            break;
    }
};

const DimensionLimitGrid = ({ dimensions, dimensionLimits, onChange, sort: sort, onSortingChange }: DimensionLimitGridProps) => {

    const gridData = dimensionLimits.map((x, idx) => ({ ...x, rowId: idx }));

    const refData = useRef(gridData);
    refData.current = gridData;

    const dispatch = (action: DispatchAction): void => {
        dimensionLimitGridReducer(refData.current, action, onChange);
    };

    const edit = (property: Property, rowId: number) => (newValue: any) =>
        dispatch({ type: ActionType.Edit, property, rowId, newValue });

    const editExpression = (property: Property, rowId: number) => (newValue: any) =>
        dispatch({
            type: ActionType.Edit, property, rowId, newValue: {
                Id: 0, Filter: newValue
            } as DimensionValueExpression
        });

    const useCellCallback = (callback: any, deps?: DependencyList) =>
        useCallback(callback, deps ? deps.concat([ sort ]) : [ sort ]);

    const DimensionNameCell = useCellCallback(({ style, dataItem }: GridCellProps) =>
        LockedDropDownCell(dimensions, toPerm(dataItem).Dimension, edit(Dimension, dataItem.rowId), style, DimensionDataQa), [dimensions]);
    const ValueCell = useCellCallback(({ dataItem }: GridCellProps) =>
        InputCell(toPerm(dataItem).Value.Filter, editExpression(Value, dataItem.rowId), ValueDataQa));
    const DescriptionCell = useCellCallback(({ dataItem }: GridCellProps) =>
        InputCell(toPerm(dataItem).Description.Filter, editExpression(Description, dataItem.rowId), DescriptionDataQa));
    const ExceptionsCell = useCellCallback(({ dataItem }: GridCellProps) =>
        ExceptionsPopupCell(toPerm(dataItem).Exceptions, edit(Exceptions, dataItem.rowId)));
    const DebitLimitValueCell = useCellCallback(({ dataItem }: GridCellProps) =>
        LimitValueCell(toPerm(dataItem).DebitLimit, ValidationType.Positive, edit(DebitLimit, dataItem.rowId), DebitLimitValueDataQa));
    const DebitLimitTypeCell = useCellCallback(({ dataItem }: GridCellProps) =>
        LimitTypeCell(toPerm(dataItem).DebitLimit, ValidationType.Positive, edit(DebitLimit, dataItem.rowId), DebitLimitTypeDataQa));
    const CreditLimitValueCell = useCellCallback(({ dataItem }: GridCellProps) =>
        LimitValueCell(toPerm(dataItem).CreditLimit, ValidationType.Negative, edit(CreditLimit, dataItem.rowId), CreditLimitValueDataQa));
    const CreditLimitTypeCell = useCellCallback(({ dataItem }: GridCellProps) =>
        LimitTypeCell(toPerm(dataItem).CreditLimit, ValidationType.Negative, edit(CreditLimit, dataItem.rowId), CreditLimitTypeDataQa));
    const HasCodingRightCell = useCellCallback(({ dataItem }: GridCellProps) =>
        CheckboxCell(toPerm(dataItem).HasCodingRight, edit(HasCodingRight, dataItem.rowId), HasCodingRightDataQa));
    const HasAuthorizationRightCell = useCellCallback(({ dataItem }: GridCellProps) =>
        CheckboxCell(toPerm(dataItem).HasAuthorizationRight, edit(HasAuthorizationRight, dataItem.rowId), HasAuthorizationRightDataQa));
    const DeleteItemCell = useCellCallback(({ dataItem }: GridCellProps) =>
        ButtonCell(() => dispatch({ type: ActionType.Delete, rowId: dataItem.rowId }), "delete-dimension-limit"));

    const unlimitedDebitLimitHeader = (props: GridHeaderCellProps) =>
        <CheckboxHeaderCell
            {...props}
            onChange={onChangeLimitedCheck}
            isChecked={gridData.some(x => x.DebitLimit) &&
                gridData.every(x => x.DebitLimit.Type === LimitType.Unlimited)} />;

    const unlimitedCreditLimitHeader = (props: GridHeaderCellProps) =>
        <CheckboxHeaderCell
            {...props}
            onChange={onChangeLimitedCheck}
            isChecked={gridData.some(x => x.CreditLimit) &&
                gridData.every(x => x.CreditLimit.Type === LimitType.Unlimited)} />;

    const codingRightHeaderCell = (props: GridHeaderCellProps) =>
        <CheckboxHeaderCell
            {...props}
            onChange={onBooleanCheck}
            info={translate("#Enterprise/authorizationGroup_codingRightDefinition")}
            isChecked={gridData.some(x => x.HasCodingRight) &&
                gridData.every(x => x.HasCodingRight)} />;

    const authorizationRightHeaderCell = (props: GridHeaderCellProps) =>
        <CheckboxHeaderCell
            {...props}
            onChange={onBooleanCheck}
            info={translate("#Enterprise/authorizationGroup_authorizationRightDefinition")}
            isChecked={gridData.some(x => x.HasAuthorizationRight) &&
                gridData.every(x => x.HasAuthorizationRight)} />;

    const isAnySortingApplied = () => sort?.some(_ => true);
    const sortSetter = useCallback((e: GridSortChangeEvent) => onSortingChange(e.sort), []);

    const onChangeLimitedCheck = (checked: boolean, propertyName: string) => {
        dispatch({ type: ActionType.ToggleAll, property: propertyName as Property, newValue: checked ? LimitType.Unlimited : LimitType.Undefined });
    };

    const onBooleanCheck = (checked: boolean, propertyName: string) => {
        dispatch({ type: ActionType.ToggleAll, property: propertyName as Property, newValue: checked });
    };

    const processedData = process(refData.current, { sort });

    const [page, setPage] = useState<PageState>(initialDataState);

    const [pageSizeValue, setPageSizeValue] = React.useState<number | string | undefined>();

    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take = event.page.take;

        if(targetEvent.value){
            setPageSizeValue(targetEvent.value);
        }

        setPage({
            ...event.page,
            take,
        })
    };

    return (
        <div data-testid="dimension-limits-grid" className="gridContainer">
            <Grid
                style={{ height: '99%' }}
                rowHeight={25}
                data={processedData.data.slice(page.skip, page.take + page.skip)}
                className="gridContainer__grid"
                total={processedData.total}
                skip={page.skip}
                take={page.take}
                sortable sort={sort}
                onSortChange={sortSetter}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [5, 10, 20],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={handlePageChange}
            >
                <GridToolbar>
                    <button className="k-button" onClick={() => dispatch({ type: ActionType.Add, defaultDimension: dimensions[0] })}
                        data-testid="add-dimension-limit">
                        <SvgIcon icon={plusIcon}/>{translate("#Enterprise/LBL_ADD")}
                    </button>
                </GridToolbar>
                <GridColumn field={Dimension} title={translate("#Enterprise/DimensionValueDimensionColumnName")} width={'200px'}
                    cell={DimensionNameCell} locked={true} {...TooltipProps} columnMenu={DimensionColumnMenuSort}
                    headerClassName={isAnySortingApplied() ? "active" : ""} />
                <GridColumn field={Value} title={translate("#Enterprise/LBL_VALUE")} width={'200px'} cell={ValueCell}
                    sortable={false} {...TooltipProps} />
                <GridColumn field={Description} title={translate("#Enterprise/description")} width={'200px'}
                    cell={DescriptionCell} sortable={false} {...TooltipProps} />
                <GridColumn field={Exceptions} title={translate("#Enterprise/exception")} width={'90px'}
                    cell={ExceptionsCell} sortable={false} {...TooltipProps} />
                <GridColumn title={translate("#Enterprise/DebitLimit")} sortable={false} {...TooltipProps}>
                    <GridColumn field={DebitLimit} title={translate("#Enterprise/LBL_LIMIT")} width={'200px'}
                        cell={DebitLimitValueCell} sortable={false} {...TooltipProps} />
                    <GridColumn field={DebitLimit} title={translate("#Enterprise/unlimited")} width={'90px'}
                        cell={DebitLimitTypeCell} sortable={false} headerCell={unlimitedDebitLimitHeader} />
                </GridColumn>
                <GridColumn title={translate("#Enterprise/CreditLimit")} sortable={false} {...TooltipProps}>
                    <GridColumn field={CreditLimit} title={translate("#Enterprise/LBL_LIMIT")} width={'200px'}
                        cell={CreditLimitValueCell} sortable={false} {...TooltipProps} />
                    <GridColumn field={CreditLimit} title={translate("#Enterprise/unlimited")} width={'90px'}
                        cell={CreditLimitTypeCell} sortable={false} headerCell={unlimitedCreditLimitHeader} />
                </GridColumn>
                <GridColumn field={HasCodingRight} title={translate("#Enterprise/codingRight")} cell={HasCodingRightCell}
                    width={'125px'} sortable={false} headerCell={codingRightHeaderCell} />
                <GridColumn field={HasAuthorizationRight} title={translate("#Enterprise/authorizationRight")}
                    cell={HasAuthorizationRightCell} width={'155px'} sortable={false} headerCell={authorizationRightHeaderCell} />
                <GridColumn cell={DeleteItemCell} width={'60px'} sortable={false} />
            </Grid>
        </div>
    );
};

export default DimensionLimitGrid;
