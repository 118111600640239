/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/showMoreButtonGroupTemplate"/>
import * as React from "react";
import { Button, MenuButton, IconArrowsChevronDownRegular } from '@medius/ui-controls';
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
interface ShowMoreButtonGroupTemplateProps {
    loadMoreTasksAction: (tasksAmount:number) => void,
    resetPageSizeAction: () => void
}

export function ShowMoreButtonGroupTemplate({loadMoreTasksAction, resetPageSizeAction}:ShowMoreButtonGroupTemplateProps) {

    return (
    <>
        <Button
            label= {getLabelTranslation("#Core/show10More")}
            variant="secondary"
            height="small"
            onClick={() => loadMoreTasksAction(10)}
        />
        <MenuButton
            hideLabel
            label={getLabelTranslation("#Core/showMoreOptions")}
            variant="secondary"
            height="small"
            icon={<IconArrowsChevronDownRegular />}
            menuSize="compact"
            data-testid="show-more-menu-button"
            items={[
              {
                key: "20",
                label: getLabelTranslation("#Core/show20More")
              },
              {
                key: "50",
                label: getLabelTranslation("#Core/show50More")
              },
              {
                key: "100",
                label: getLabelTranslation("#Core/show100More")
              },
              {
                key: "Reset",
                label: getLabelTranslation("#Core/reset")
              }
            ]}
            onItemSelected={(item) => {
              if(item.key !== "Reset")
                loadMoreTasksAction(parseInt(item.key));
              else {
                resetPageSizeAction();
              }
            }}
          />
    </>
    );
}