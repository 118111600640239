///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts"/>

//For now the questions are static. Once we decide how to decide which questions should be displayed this should be changed.
export const tellMeMoreAboutTheSupplier = "#Core/TellMeMoreAboutTheSupplier";
export const shouldIApproveThisInvoice =  "#Core/ShouldIApproveThisInvoice";
export const howDoIRejectThisInvoice = "#Core/HowDoIRejectThisInvoice";
export const whyHaveIReceivedThisInvoice = "#Core/WhyHaveIReceivedThisInvoice";
export const translateTheInvoiceContextToMyLanguage = "#Core/TranslateTheInvoiceContextToMyLanguage";
export const summarizeTheInvoiceContext = "#Core/SummarizeTheInvoiceContext";

export const displayedPredefinedQuestionTranslationTags = [
    tellMeMoreAboutTheSupplier,
    shouldIApproveThisInvoice,
    howDoIRejectThisInvoice,
    whyHaveIReceivedThisInvoice,
    translateTheInvoiceContextToMyLanguage,
    summarizeTheInvoiceContext
];

export const operationalPredefinedQuestions = [
    tellMeMoreAboutTheSupplier,
    shouldIApproveThisInvoice,
    whyHaveIReceivedThisInvoice,
    summarizeTheInvoiceContext
];

export const nonOperationalPredefinedQuestions = [
    tellMeMoreAboutTheSupplier,
    summarizeTheInvoiceContext,
    translateTheInvoiceContextToMyLanguage
];

export enum EntityLogMessageScenarios {
    NonPOManualRoutingLineModified = "#ExpenseInvoice/CodingLineManuallyModified_user_fromValues_toValues",
    NonPOManualRoutingLineCreated = "#ExpenseInvoice/CodingLineManuallyAdded_user_codingLineValues",
    NonPOCodingLineAutomaticRouting = "#PurchaseToPay/lineNumber_businessRule_reference",
    GoToTaskResultLog = "#Core/GoToTaskResultLog",
    HeadDeviationAutomaticRouting = "#PurchaseToPay/deviation_businessRule_reference",
    HeadDeviationNextAuthorizer = "#PurchaseToPay/nextAuthorizer_deviation_businessRule_reference",
    NextAuthorizerNotFoundDistributionReset = "#ExpenseInvoice/resetDistributionWhenNextAuthorizerNotFound",
    NonPOCodingLineNextAuthorizer = "#PurchaseToPay/nextAuthorizer_lineNumber_businessRule_reference",
    LineDeviationAutomaticRouting = "#PurchaseToPay/lineNumber_deviation_businessRule_reference",
    LineDeviationNextAuthorizer = "#PurchaseToPay/nextAuthorizer_lineNumber_deviation_businessRule_reference",
    FourEyesPrinciple = "#PurchaseToPay/fourEyesPrincipleLogMessage",
    FourEyesPrincipleOnLineLevel = "#PurchaseToPay/lineLevelFourEyesPrincipleLogMessage",
    FourEyesPrincipleOnHeadLevel = "#PurchaseToPay/headLevelFourEyesPrincipleLogMessage",
    POLineDeviationRerouted = "#OrderbasedInvoice/lineDeviationRerouted",
    POHeadDeviationRerouted = "#OrderbasedInvoice/headDeviationRerouted",
    InvoiceAmountApprovalRerouted = "#OrderbasedInvoice/invoiceAmountApprovalWasReroutedToBy",
    InvoiceAutomaticRouting = "#PurchaseToPay/businessRule_reference",
    InvoiceNextAuthorizer = "#PurchaseToPay/nextAuthorizer_businessRule_reference"
}

//The rules being uppercase is to simplify the comparison to business rules, as some rules are called the same just with different casing for different perspectives
//For example PoHeadResponsible for DeviationNextAuthorizerPerspective and POHeadResponsible for InvoiceNextAuthorizerPerspective
export enum EntityLogBusinessRulesUppercase{
    SupplierResponsible = "SUPPLIERRESPONSIBLE",
    InvoiceReferenceByUsername = "INVOICEREFERENCEBYUSERNAME",
    InvoiceReferenceByRolename = "INVOICEREFERENCEBYROLENAME",
    InvoiceReference = "INVOICEREFERENCE",
    CompanyResponsible = "COMPANYRESPONSIBLE",
    RoutingResponsible = "ROUTINGRESPONSIBLE",
    Dimension1Responsible = "DIMENSION1RESPONSIBLE",
    Dimension2Responsible = "DIMENSION2RESPONSIBLE",
    Dimension3Responsible = "DIMENSION3RESPONSIBLE",
    Dimension4Responsible = "DIMENSION4RESPONSIBLE",
    Dimension5Responsible = "DIMENSION5RESPONSIBLE",
    Dimension6Responsible = "DIMENSION6RESPONSIBLE",
    Dimension7Responsible = "DIMENSION7RESPONSIBLE",
    Dimension8Responsible = "DIMENSION8RESPONSIBLE",
    Dimension9Responsible = "DIMENSION9RESPONSIBLE",
    Dimension10Responsible = "DIMENSION10RESPONSIBLE",
    Dimension11Responsible = "DIMENSION11RESPONSIBLE",
    Dimension12Responsible = "DIMENSION12RESPONSIBLE",
    UserResponsible = "USERRESPONSIBLE",
    PoLineResponsible = "POLINERESPONSIBLE",
    PoLineResponsible2 = "POLINERESPONSIBLE2",
    PoLineUsernameResponsible = "POLINEUSERNAMERESPONSIBLE",
    PoLineUsernameResponsible2 = "POLINEUSERNAMERESPONSIBLE2",
    PoLineRolenameResponsible = "POLINEROLENAMERESPONSIBLE",
    PoLineRolenameResponsible2 = "POLINEROLENAMERESPONSIBLE2",
    POHeadResponsible = "POHEADRESPONSIBLE",
    POHeadReference = "POHEADREFERENCE",
    POHeadUsernameResponsible = "POHEADUSERNAMERESPONSIBLE",
    POHeadUsernameReference = "POHEADUSERNAMEREFERENCE",
    POHeadResponsible2 = "POHEADRESPONSIBLE2",
    POHeadReference2 = "POHEADREFERENCE2",
    POHeadUsernameResponsible2 = "POHEADUSERNAMERESPONSIBLE2",
    POHeadUsernameReference2 = "POHEADUSERNAMEREFERENCE2",
    POHeadRolenameResponsible = "POHEADROLENAMERESPONSIBLE",
    POHeadRolenameReference = "POHEADROLENAMEREFERENCE",
    POHeadRolenameResponsible2 = "POHEADROLENAMERESPONSIBLE2",
    POHeadRolenameReference2 = "POHEADROLENAMEREFERENCE2",
    ItemReference = "ITEMREFERENCE",
    ItemReferenceByUsername = "ITEMREFERENCEBYUSERNAME",
    ItemReferenceByRolename = "ITEMREFERENCEBYROLENAME",
    DeviationResponsible = "DEVIATIONRESPONSIBLE",
    InvoiceAuthorizationResponsible = "INVOICEAUTHORIZATIONRESPONSIBLE"
}

export const feedbackOptions = [
    "dontLikeTheStyle",
    "notFactuallyCorrect",
    "didntFullyFollowInstructions",
    "refusedWhenItShouldntHave",
    "beingLazy"
];

export const invoiceInsightsCommentsLimit = 3;

export const translateThisInvoiceQuestionId = 14;

export const summarizeThisInvoiceQuestionId = 15;

export enum copilotEvents {
    PredefinedQuestionFromInvoiceImageTooltip = "COPILOT_EVENT_INVOICE_IMAGE_TOOLTIP",
    PredefinedQuestionFromNotificationWindow = "COPILOT_EVENT_NOTIFICATION_WINDOW",
    HideNotificationWindow = "COPILOT_EVENT_HIDE_NOTIFICATION_WINDOW",
    SummarizeThisInvoice = "COPILOT_EVENT_SUMMARIZE_THIS_INVOICE",
    TranslateThisInvoice = "COPILOT_EVENT_TRANSLATE_THIS_INVOICE",
    FreeTextQuestion = "COPILOT_EVENT_FREE_TEXT_QUESTION",
}