/* eslint-disable react-hooks/exhaustive-deps */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotRoot"/>
import * as React from "react";
import { SidebarOverlay, LocalizationContext, StringMap, ImageLogoMediusCopilot } from "@medius/ui-controls";
import { newGuid } from "Core/Medius.Core.Web/Scripts/lib/uniqueGuid";
import { useClickOutsideReference } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useClickOutsideReference";
import { CopilotEvent, CopilotEventPayload, IMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { IAgentIconComponentProps } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { adjustPayloadBasedOnCopilotEventType, calculateCopilotSidebarAnchorCoords } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { CopilotButton } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton";
import { MediusCopilotInteractionType, trackInteractionEvent } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights";
import { copilotEvents } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { CopilotSidebar } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotSidebar/CopilotSidebar";
import { CopilotContextProvider } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotContextProvider";

export interface IAgentProps extends IAgentIconComponentProps {
    isInFullscreen: boolean;
}

export default function CopilotRoot({
    taskId,
    documentId,
    isOperational,
    isInFullscreen,
    workflowStepName,
    invoiceType,
    getDelegationMessage,
    getEscalationMessage,
    predefinedQuestions,
    isDocumentImport
}: IAgentProps) {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [copilotEventPayload, setCopilotEventPayload] = React.useState<CopilotEventPayload | null>(null);
    const [messages, setMessages] = React.useState<IMessage[]>([]);
    const [processedMessage, setProcessedMessage] = React.useState<IMessage>();
    const [isStreamActive, setIsStreamActive] = React.useState(false);
    const [requestTimeout, setRequestTimeout] = React.useState<NodeJS.Timeout>();
    const [abortController, setAbortController] = React.useState<AbortController>();
    const [isAgentAvailable, setIsAgentAvailable] = React.useState(true);
    const [isLoadingMessages, setIsLoadingMessages] = React.useState(true);

    const conversationId = newGuid();
    const iconRef = React.useRef(null);    
    useClickOutsideReference(iconRef, () => {
        if (isSidebarOpen)
            closeSidebar();
    });

    const closeSidebar = () => {
        setCopilotEventPayload(null);
        setIsSidebarOpen(false);
        trackInteractionEvent(MediusCopilotInteractionType.CloseSidebar);
    };

    const openSidebar = () => {
        window.postMessage({ type: copilotEvents.HideNotificationWindow}, '*');
        trackInteractionEvent(MediusCopilotInteractionType.OpenSidebarWithCopilotButton);
        setIsSidebarOpen(true);
    };

    //[event api] react on global copilot events and adjust their payload
    //subscribe to message events on root component mount
    React.useEffect(() => {
        const handleMessageWindowEvent = (event: CopilotEvent) => {
            const { type, payload } = event.data;
            const scenarioAdjustedPayload = adjustPayloadBasedOnCopilotEventType(type, payload);

            if (!scenarioAdjustedPayload || (scenarioAdjustedPayload.questionId && scenarioAdjustedPayload.prompt)) {
                return;
            }

            if (!isSidebarOpen) {
                setIsSidebarOpen(true);
                window.postMessage({ type: copilotEvents.HideNotificationWindow}, '*');
            }
            trackInteractionEvent(MediusCopilotInteractionType.OpenSidebarWithNotification, taskId);
            if(copilotEventPayload === null){
                setCopilotEventPayload(scenarioAdjustedPayload);
            }
        };

        window.addEventListener('message', handleMessageWindowEvent);

        return () => {
            window.removeEventListener('message', handleMessageWindowEvent);
        };
    }, []);

    return (
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
        <IntlProvider locale={getCurrentCulture()}>
        <LocalizationProvider language="current-language">
        <CopilotContextProvider documentId={documentId} invoiceType={invoiceType} isOperational={isOperational} predefinedQuestions={predefinedQuestions} workflowStepName={workflowStepName} copilotEventPayload={copilotEventPayload} setCopilotEventPayload={setCopilotEventPayload} messages={messages} setMessages={setMessages} processedMessage={processedMessage} setProcessedMessage={setProcessedMessage} isStreamActive={isStreamActive} setIsStreamActive={setIsStreamActive} requestTimeout={requestTimeout} setRequestTimeout={setRequestTimeout} abortController={abortController} setAbortController={setAbortController} isAgentAvailable={isAgentAvailable} setIsAgentAvailable={setIsAgentAvailable} isLoadingMessages={isLoadingMessages} setIsLoadingMessages={setIsLoadingMessages} conversationId={conversationId} taskId={taskId} isDocumentImport={isDocumentImport}>
        <div className="mediusAgent">
            <CopilotButton 
                onClick={openSidebar} 
                data-testid="apaCopilotButton" 
                label={"Medius Copilot"}
                isMainCopilotButton
            />
            <div ref={iconRef} data-testid="apaAgentAnchor" style={calculateCopilotSidebarAnchorCoords(isInFullscreen)}></div>
            <SidebarOverlay
                icon={<ImageLogoMediusCopilot className="mediusAgent__titleImage" />}
                contentBackground="light"
                title={"Medius Copilot"}
                alignment="right"
                isOpen={isSidebarOpen}
                targetRef={iconRef}
                onClose={closeSidebar}
                data-testid="apa-agent"
            >
                { isSidebarOpen && <CopilotSidebar 
                    getDelegationMessage={getDelegationMessage}
                    getEscalationMessage={getEscalationMessage}
                />}
            </SidebarOverlay>
        </div>
        </CopilotContextProvider>
        </LocalizationProvider>
        </IntlProvider>
        </LocalizationContext.Provider>
    );
}