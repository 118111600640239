/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useClickOutsideReference"/>
import { useEffect, MutableRefObject } from "react";

type CallbackWithSideEffects = (e: MouseEvent) => void;

export function useClickOutsideReference(ref: MutableRefObject<any>, cb: CallbackWithSideEffects) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    const targetElement = event.target as Element;
                    if(
                        targetElement.parentElement && 
                        targetElement.parentElement.getAttribute("close-ai-agent-modal") !== 'false' &&
                        targetElement && 
                        targetElement.getAttribute("close-ai-agent-modal") !== 'false'
                    ) {
                        cb(event);
                    }
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, cb]);
}