///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext" />
import { useContext } from "react";
import { CopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotContextProvider";

export const useCopilotContext = () => {
    const context = useContext(CopilotContext);
    
    if (context === undefined) {
        throw new Error('useCopilotContext must be used within a child component of CopilotContextProvider');
    }
  
    return context;
};