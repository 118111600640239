/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/model" />
import {AutocompleterViewModel as BaseViewModel} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as userUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { create as createBreakablePipe } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/breakablePipeFactory";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class AutocompleterViewModel extends BaseViewModel {
    constructor(params: any) {
        super(params);
        if (params.options.useDefault && !this.value()) {
            this.setValue(userUtils.getDefaultCompany(), this.context);
        }
    }

    request(searchMethod: (searchText: string, maxResults: number, offset: number, options: any) => any) {
        const searchText = this.inputValue().trim(),
            context = this.context;

        super.cancelPendingRequest();
        this.isLoading(true);
        this.activeRequest = createBreakablePipe(searchMethod(searchText, this.maxResults + this.totalLoaded, this.totalLoaded, this.dataProviderOptions));

        return this.activeRequest.getPipedDeferred().done((results: any) => {
            this.onSuggestionsReturned(results, context);
        }).always(() => {
            this.isLoading(false);
            this.activeRequest = null;
        });
    }

    onSuggestionsReturned(data: any, context: any) {
        const wrappedResults = this.wrapResultsIfNeeded(data),
            suggestedItemIndex = !isNullOrUndefined(wrappedResults.SelectedItemIndex)
                ? wrappedResults.SelectedItemIndex += this.totalLoaded
                : null,
            hasItems = wrappedResults.Results.length > 0;

        let suggestedItem = wrappedResults.Results[suggestedItemIndex] || null;

        if (this.options.preselectFirstValue && wrappedResults.Results.length === 1)
            suggestedItem = wrappedResults.Results[0];

        if (!hasItems && this.customValueHandler !== undefined) {
            this.customValueHandler(this.inputValue);
        } else if (this.hasFocus()) {
            
            const newResults = wrappedResults.Results;

            this.isFullyLoaded = newResults.length < this.maxResults;
            this.totalLoaded = wrappedResults.Results.length;

            this.items.push(...newResults);

            this.suggestedItem(suggestedItem);
            this.showResults(hasItems);
        } else {
            if (this.isInputEmpty())
                suggestedItem = null;

            this.selectItem(suggestedItem, context);
        }
    }
}

export function create(params: any) {
    return new AutocompleterViewModel(params);
}
