/* eslint-disable no-case-declarations */
/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model" />
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import * as _ from "underscore";
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as defaultMetadataGeneratorFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator";
import { BreakablePipe, create as createBreakablePipe } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/breakablePipeFactory";
import * as performanceLogFactory from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/base";
import { IconStatusCheckCircleFill, IconStatusCheckCircleRegular } from "@medius/ui-controls";

let defaultMetadataGenerator: ReturnType<typeof defaultMetadataGeneratorFactory["create"]>;

const getDefaultMetadataGeneratorInstance = () => {
    if (!defaultMetadataGenerator)
        defaultMetadataGenerator = defaultMetadataGeneratorFactory.create();

    return defaultMetadataGenerator;
};

function getPlaceholder(options: any) {
    if (options.placeholder)
        return options.placeholder;

    if (options.placeholderKey)
        return globalization.getLabelTranslation(options.placeholderKey);

    return "";
}

export class AutocompleterViewModel {
    context: any;
    isValidatedExternally: boolean;
    value: any;
    errors: any;
    isValid: any;
    selectItemFromCodeSubscription: any;
    placeholder: any;
    dataProvider: any;
    dataProviderOptions: any;
    options: any;
    maxResults: any;
    Template: any;
    resultsTemplate: any;
    customValueHandler: any;
    onSelectedHandler: any;
    customGetItemTextValue: any;
    customGetItemDescription: any;

    isLoading: any;
    hasFocus: ko.Observable<any>;
    valueSubscription: any;
    stopEventBubblingOnEnter: any;
    requestSuggestionsDebounced: (() => void);
    hasError: any;
    waitForDebouncedRequest: boolean;
    isValueUpdated: boolean;
    hasBeenValidated: boolean;
    activeRequest: BreakablePipe;
    isFullyLoaded: boolean;
    totalLoaded: number;
    inputValue: ko.Observable<any>;
    items: ko.ObservableArray<any>;
    suggestedItem: ko.Observable<any>;
    showResults: ko.Observable<boolean>;
    resultItemTemplate: any;
    showDimensionConfidenceIcon: ko.PureComputed<boolean>;
    showTaxGroupConfidenceIcon: ko.PureComputed<boolean>;
    showAuthorizerConfidenceIcon: ko.PureComputed<boolean>;
    highConfidenceIcon: ko.Computed;
    lowConfidenceIcon: ko.Computed;

    constructor(params: any) {
        this.context = params.context || contextFactory();

        if (params.validatedValue) {
            this.isValidatedExternally = true;
            this.value = params.validatedValue.internalValue;
            this.errors = params.validatedValue.errors;
            this.isValid = params.validatedValue.isValid;
        } else {
            this.isValidatedExternally = false;
            this.value = params.value;
            this.errors = ko.observableArray();
            this.isValid = ko.computed(() => {
                return this.errors().length === 0;
            });
            if (params.options.selectedItemFromCode) {
                this.selectItemFromCodeSubscription = params.options.selectedItemFromCode.subscribe((item: any) => {
                    this.selectItem(item, this.context);
                });
            }
        }

        this.placeholder = getPlaceholder(params.options);
        this.dataProvider = params.dataProvider;
        this.dataProviderOptions = params.dataProviderOptions;
        this.options = params.options;
        this.maxResults = params.maxResults;
        this.Template = params.template;
        this.resultsTemplate = params.resultsTemplate;
        this.resultItemTemplate = params.resultItemTemplate;
        this.customValueHandler = params.options.customValueHandler;
        this.onSelectedHandler = params.onSelectedHandler;
        this.customGetItemTextValue = params.options.customGetItemTextValue;
        this.customGetItemDescription = params.options.customGetItemDescription;
        this.waitForDebouncedRequest = false;
        this.isValueUpdated = true;
        this.hasBeenValidated = false;
        this.activeRequest = null;
        this.isFullyLoaded = false;
        this.totalLoaded = 0;
        this.inputValue = ko.observable();
        this.items = ko.observableArray();
        this.suggestedItem = ko.observable(null);
        this.showResults = ko.observable(false);

        this.isLoading = params.isLoading || ko.observable(false);
        this.hasFocus = ko.observable(koUtils.unpack(params.hasFocus));

        this.inputValue(this.getItemTextValue(this.value()));
        this.valueSubscription = this.value.subscribe(() => {
            this.inputValue(this.getItemTextValue(this.value()));
        });

        this.stopEventBubblingOnEnter = ko.unwrap(params.options.stopEventBubblingOnEnter) || false;

        if (this.options.validateInputOnInit)
            this.validateInput();

        //debounce is not stateless function so it cannot be shared
        const requestSuggestionsDebounce = _.debounce(() => {
            if (this.waitForDebouncedRequest)
                this.requestSuggestions();
        }, params.throttle);

        this.requestSuggestionsDebounced = () => {
            this.waitForDebouncedRequest = true;
            requestSuggestionsDebounce();
        };

        this.showDimensionConfidenceIcon = ko.pureComputed(() =>
            this.value() != null
            && this.isValid()
            && !this.isLoading()
            && !this.options.hasError()
            && !this.options.disabled()
            && !this.options.tooltipData.IsManual());

        this.showTaxGroupConfidenceIcon = ko.pureComputed(() =>
            this.value() != null
            && this.isValid()
            && !this.isLoading()
            && !this.options.hasError());

        this.showAuthorizerConfidenceIcon = ko.pureComputed(() =>
            this.value() != null
            && this.isValid()
            && !this.isLoading()
            && this.errors().length == 0);

        this.highConfidenceIcon = ko.computed(() =>
        ({
            functionComponent: IconStatusCheckCircleFill,
            props: {
                color: "ok",
                size: "custom"
            }
        }));

        this.lowConfidenceIcon = ko.computed(() =>
        ({
            functionComponent: IconStatusCheckCircleRegular,
            props: {
                color: "warning",
                size: "custom"
            }
        }));
    }

    dispose() {
        if (!this.isValidatedExternally)
            this.isValid.dispose();

        if (this.selectItemFromCodeSubscription)
            this.selectItemFromCodeSubscription.dispose();

        this.valueSubscription.dispose();
        this.context = null;
    }

    getItemTextValue(item: any) {
        if (this.customGetItemTextValue)
            return this.customGetItemTextValue(item);

        return _.isObject(item)
            ? getDefaultMetadataGeneratorInstance().getMetadata(item).text || ""
            : item || "";
    }

    getItemDescription(item: any) {
        if (this.customGetItemDescription)
            return this.customGetItemDescription(item);

        const metadata = getDefaultMetadataGeneratorInstance().getMetadata(item);
        return metadata.longText || this.getItemTextValue(item);
    }

    hideResults() {
        this.suggestedItem(null);
        this.showResults(false);
    }

    isInputEmpty() {
        return isEmptyString(this.inputValue());
    }

    //mousedown prevents loosing focus
    itemMouseDown(item: any) {
        this.selectItem(item, this.context);
        this.reset();
    }

    //needed to prevent disappering result list
    //when clicked on scrollbar
    itemMouseDownPreventDefault(item: any, event: any) {
        event.preventDefault();
    }

    onBlur() {
        if (this.waitForDebouncedRequest) {
            this.requestExactMatch();
        } else if (this.isValueUpdated === false) {
            if (this.activeRequest === null) {
                //if there is still active request on request.done validation will be done
                if (this.customValueHandler !== undefined)
                    this.customValueHandler(this.inputValue);
                else if (this.suggestedItem() === null)
                    this.selectItem(null, this.context);
                else
                    this.selectSuggested();
            }
        } else if (this.hasBeenValidated === false)
            this.validateInput();

        this.reset();
    }

    onKeyDown(data: any, event: any) {
        switch (event.keyCode) {
            case 32: //space
                if (this.isInputEmpty() === false)
                    return true;

                this.reset();
                this.requestSuggestions();
                return false;

            case 13: //enter
                if (!this.stopEventBubblingOnEnter) {
                    this.selectSuggested();
                    this.handleAfterSelectCallback();
                    return false;
                }

                //bubbling is stopped if selected value has changed
                const prev = ko.unwrap(this.value);
                this.selectSuggested();
                const current = ko.unwrap(this.value);

                if (prev !== current)
                    event.stopPropagation();

                this.handleAfterSelectCallback();
                return false;

            case 27: //esc
                this.reset();
                return false;

            case 38: //up
                this.suggestPrevious();
                return false;

            case 40: //down
                if (this.showResults())
                    this.suggestNext();
                else
                    this.requestSuggestions();

                return false;

            default:
                return true;
        }
    }

    onScroll(model: any, event: any) {
        const elem = event.target;

        if (this.scrollReachedBottom(elem))
            this.requestSuggestions();
    }

    onSuggestionsReturned(data: any, context: any) {
        const wrappedResults = this.wrapResultsIfNeeded(data),
            suggestedItemIndex = !isNullOrUndefined(wrappedResults.SelectedItemIndex)
                ? wrappedResults.SelectedItemIndex += this.totalLoaded
                : null,
            hasItems = wrappedResults.Results.length > 0;

        let suggestedItem = wrappedResults.Results[suggestedItemIndex] || null;

        if (this.options.preselectFirstValue && wrappedResults.Results.length === 1)
            suggestedItem = wrappedResults.Results[0];

        if (!hasItems && this.customValueHandler !== undefined) {
            this.customValueHandler(this.inputValue);
        } else if (this.hasFocus()) {      
            const newResults = wrappedResults.Results.slice(this.totalLoaded);

            this.isFullyLoaded = newResults.length < this.maxResults;
            this.totalLoaded = wrappedResults.Results.length;

            this.items.push(...newResults);

            this.suggestedItem(suggestedItem);
            this.showResults(hasItems);
        } else {
            if (this.isInputEmpty())
                suggestedItem = null;

            this.selectItem(suggestedItem, context);
        }
    }

    onTextChanged() {
        this.cancelPendingRequest();
        if (this.isInputEmpty()) {
            this.selectItem(null, this.context);
        } else {
            this.reset();
            this.requestSuggestionsDebounced();
            this.isValueUpdated = false;
        }
    }

    onKeyUp(data: any, event: any) {
        //this function is created to behave like onTextChanged added as an event handler to input event
        //it is done in this way because of bug in IE: https://connect.microsoft.com/IE/feedback/details/810538/ie-11-fires-input-event-on-focus
        //in our case focus event is responsible for validation, and there are cases when we don't want to validate anything
        //keycodes copied from http://stackoverflow.com/questions/12467240/determine-if-javascript-e-keycode-is-a-printable-non-control-character
        const keycode = event.keyCode,
            isChangingInput = (keycode >= 46 && keycode < 58) || // delete + number keys
                (keycode > 64 && keycode < 91) || // letter keys
                (keycode > 95 && keycode < 112) || // numpad keys
                (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                (keycode > 218 && keycode < 223) || // [\]' (in order)
                keycode === 8;  // backspace

        if (!isChangingInput)
            return;

        this.onTextChanged();
    }

    requestExactMatch() {
        this.request(this.dataProvider.exact.bind(this.dataProvider));
    }

    requestSuggestions() {
        const measurementNeeded = !!this.options.measureUxCategory,
            requestMethod = this.dataProvider.search.bind(this.dataProvider);

        let performanceLog: any;

        if (measurementNeeded)
            performanceLog = performanceLogFactory.start(this.options.measureUxCategory);

        this.request(requestMethod).done(() => {
            if (measurementNeeded)
                performanceLog.stop();
        });
    }

    request(searchMethod: any) {
        const searchText = this.inputValue().trim(),
            context = this.context;

        this.cancelPendingRequest();
        this.isLoading(true);
        this.activeRequest = createBreakablePipe(searchMethod(searchText, this.maxResults + this.totalLoaded, this.dataProviderOptions));

        return this.activeRequest.getPipedDeferred().done((results: any) => {
            this.onSuggestionsReturned(results, context);
        }).always(() => {
            this.isLoading(false);
            this.activeRequest = null;
        });
    }

    protected cancelPendingRequest(): void {
        this.waitForDebouncedRequest = false;
        if (this.activeRequest) {
            this.activeRequest.breakPipe();
        }
        this.isLoading(false);
        this.activeRequest = null;
    }

    isValueMissing(item: any) {
        return !item && this.inputValue();
    }

    isDummy(item: any) {
        return !isNullOrUndefined(this.dataProvider.isDummy) && this.dataProvider.isDummy(item);
    }

    performValidation(item: any) {
        if (!this.isValidatedExternally) {
            this.errors.removeAll();

            if (this.isValueMissing(item) || this.isDummy(item)) //item does not exist
                this.errors.push(globalization.getLabelTranslation("#Core/couldNotFindValue"));

            if (!item && !this.inputValue() && this.options.required)
                this.errors.push(globalization.getLabelTranslation("#Core/required"));
        }

        return this.errors().length === 0;
    }

    reset() {
        this.hideResults();
        this.items([]);
        this.isFullyLoaded = false;
        this.totalLoaded = 0;
    }

    scrollReachedBottom(element: any) {
        return !this.isFullyLoaded && element.scrollTop > (element.scrollHeight - element.offsetHeight);
    }

    selectSuggested() {
        if (this.suggestedItem() === null)
            return;

        this.selectItem(this.suggestedItem(), this.context);
    }

    selectItem(item: any, context: any) {
        const previousText = this.inputValue();

        if (this.isValueMissing(item))
            item = this.dataProvider.createDummy(this.inputValue());

        if (this.isValidatedExternally)
            this.setValue(item, context);
        else if (this.performValidation(item) || this.isDummy(item))
            this.setValue(item, context);
        else {
            this.setValue(null, context);
            this.inputValue(previousText);
        }

        this.reset();
    }

    handleAfterSelectCallback() {
        if (this.onSelectedHandler !== undefined && this.value() !== null) {
            this.onSelectedHandler();
            this.reset();
        }
    }

    setValue(item: any, context: any) {
        if (this.options.useViewModels)
            this.value(context.create(item));
        else
            this.value(item);

        this.isValueUpdated = true;
    }

    suggestNext() {
        const index = _.indexOf(this.items(), this.suggestedItem()),
            isLastItem = index === this.items().length - 1;

        if (!this.suggestedItem())
            this.suggestedItem(this.items()[0]);

        if (isLastItem)
            this.suggestedItem(this.items()[0]);
        else
            this.suggestedItem(this.items()[index + 1]);
    }

    suggestPrevious() {
        const index = _.indexOf(this.items(), this.suggestedItem()),
            isFirstItem = index === 0;

        if (!this.suggestedItem())
            return;

        if (isFirstItem)
            this.suggestedItem(this.items()[this.items().length - 1]);
        else
            this.suggestedItem(this.items()[index - 1]);
    }

    validateInput() {
        return this.hasBeenValidated
            ? this.hasError()
            : this.performValidation(this.value()); //initial validation
    }

    wrapResultsIfNeeded(data: any) {
        return _.isArray(data)
            ? {
                Results: data,
                SelectedItemIndex: null
            }
            : data;
    }
}

export function create(params: any) {
    return new AutocompleterViewModel(params);
}

export const derive = AutocompleterViewModel;
