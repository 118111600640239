/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/accessState"/>

import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";

interface Accesses {
    documentManagement: boolean;
    emailSettings: boolean;
    resolutionConfiguration: boolean;
    demoManager: boolean;
    passwordManagement: boolean;
    importManagement: boolean;
    documentCacheManagement: boolean;
    masterDataReindex: boolean;
    messageManagement: boolean;
    payApprovalRule: boolean;
    importConfiguration: boolean;
    enabledModules: Array<Module>;
    supportPortal: boolean;
    permissionGroups: boolean;
    sendInvoiceAsEmail: boolean;
    procurementV2: boolean;
    contractCategoryAdministration: boolean;
    prepaymentConfigurationAdministration: boolean;
    purgeInvoices: boolean;
    userAdministration: boolean;
    procurementAdministration: boolean;
    deliveryPartnersSupport: boolean;
    globalSettingsLinks: boolean;
    budgetConfigurationAdministration: boolean;
    budgetKeyAdministration: boolean;
    budgetKeyEdit: boolean;
    domainAuthentication : boolean;
    addressManagement: boolean;
    catalogManagement: boolean;
}

export const AccessLoadedAction = "ACCESSES_LOADED";

export function accessesReducer(state = null as any as Accesses, action: { type: string; payload: any }): Accesses {
    switch (action.type) {
        case AccessLoadedAction:
            return { ...action.payload };
        default:
            return state;
    }
}

export function setAccesses(accesses: Accesses) {
    return {
        type: AccessLoadedAction,
        payload: accesses
    };
}