/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/Addresses/addresses"/>
import * as ko from "knockout";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { isAddressManagementEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import { NewAddressManagementPageInfoDialog } from "./newAddressManagementPageInfoDialog";
import { NewAddressManagementEmptyState } from "./newAddressManagementEmptyState";

class Addresses {
    public entity: any;
    public context: any;

    public AvailableAddressTypes: ko.ObservableArray<any>;
    public AddressType: ko.Observable<any>;
    public Name: ko.Observable<any>;
    public Recipient: ko.Observable<any>;
    public StreetAddress: ko.Observable<any>;
    public StreetAddress2: ko.Observable<any>;
    public Division: ko.Observable<any>;
    public Building: ko.Observable<any>;
    public Location: ko.Observable<any>;
    public City: ko.Observable<any>;
    public State: ko.Observable<any>;
    public Zip: ko.Observable<any>;
    public Country: ko.Observable<any>;
    public Telephone: ko.Observable<any>;
    public Fax: ko.Observable<any>;
    public Homepage: ko.Observable<any>;
    public Email: ko.Observable<any>;

    public ShowNewAddressManagementPageDialog: ko.Observable<boolean>;
    public NewAddressManagementPageInfoDialogComponent: ko.PureComputed<any>;
    public AddressesEmptyStateComponent: ko.PureComputed<any>;
    public HasAddresses: ko.PureComputed<boolean>;
    
    constructor(entity: any, context: any) {
        this.entity = entity;
        this.context = context;
        
        this.AvailableAddressTypes = ko.observableArray([]);
        this.AddressType = ko.observable();
        this.Name = ko.observable(entity.Name());
        this.Recipient = ko.observable();
        this.StreetAddress = ko.observable();
        this.StreetAddress2 = ko.observable();
        this.Division = ko.observable();
        this.Building = ko.observable();
        this.Location = ko.observable();
        this.City = ko.observable();
        this.State = ko.observable();
        this.Zip = ko.observable();
        this.Country = ko.observable();
        this.Telephone = ko.observable();
        this.Fax = ko.observable();
        this.Homepage = ko.observable();
        this.Email = ko.observable();
        this.HasAddresses = ko.pureComputed(() => this.context.get('company-addresses-isPv2Enabled')() === true 
            && isAddressManagementEnabled() 
            && entity.Addresses()?.length !== 0);
        this.ShowNewAddressManagementPageDialog = ko.observable(false);
        this.AddressesEmptyStateComponent = ko.pureComputed(() => {
            return {
                functionComponent: NewAddressManagementEmptyState,
                props: {}
            };
        });
        this.NewAddressManagementPageInfoDialogComponent = ko.pureComputed(() => {
            return {
                functionComponent: NewAddressManagementPageInfoDialog,
                props: {
                    isOpen: this.ShowNewAddressManagementPageDialog(),
                    closeDialog: () => { this.ShowNewAddressManagementPageDialog(false); },
                }
            };
        });

        sync.load("Medius.Core.Entities.AddressType").then((list: any[]) => {
            this.AvailableAddressTypes(list);
        });
    }

    removeRow(rowToRemove: any) {
        if(this.context.get('company-addresses-isPv2Enabled')() === true && isAddressManagementEnabled()) {
            this.ShowNewAddressManagementPageDialog(true);
            return;
        }
        this.entity.Addresses.remove(rowToRemove);
    }

    addRow() {
        if(this.context.get('company-addresses-isPv2Enabled')() === true && isAddressManagementEnabled()) {
            this.ShowNewAddressManagementPageDialog(true);
            return;
        }
        let newAddress = sync.getNew("Medius.Core.Entities.Address");
        newAddress = this.context.create(newAddress);

        newAddress.Type = ko.observable(this.AddressType());
        newAddress.AddressFields().Name = this.Name();
        newAddress.AddressFields().Recipient = this.Recipient();
        newAddress.AddressFields().StreetAddress = this.StreetAddress();
        newAddress.AddressFields().StreetAddress2 = this.StreetAddress2();
        newAddress.AddressFields().Division = this.Division();
        newAddress.AddressFields().Building = this.Building();
        newAddress.AddressFields().Location = this.Location();
        newAddress.AddressFields().City = this.City();
        newAddress.AddressFields().Zip = this.Zip();
        newAddress.AddressFields().State = this.State();
        newAddress.AddressFields().Country = this.Country();
        newAddress.AddressFields().Telephone = this.Telephone();
        newAddress.AddressFields().Fax = this.Fax();
        newAddress.AddressFields().Homepage = this.Homepage();
        newAddress.AddressFields().Email = this.Email();
        
        this.entity.Addresses.push(newAddress);

        this.Recipient(null);
        this.StreetAddress(null);
        this.StreetAddress2(null);
        this.Division(null);
        this.Building(null);
        this.Location(null);
        this.City(null);
        this.Zip(null);
        this.Country(null);
        this.Telephone(null);
        this.Fax(null);
        this.Homepage(null);
        this.Email(null);
        this.State(null);
    }

    dispose() {}
}

export default function(entityWithContext: any) {
    return new Addresses(entityWithContext.Entity, entityWithContext.Context);
}
