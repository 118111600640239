/// <amd-module name="Core/Medius.Core.Web/Scripts/Helpers/migrationApisAvailableEndpointsList"/>

export function getAvailableMigratedWebApiEndpointsList(): string[] {
    return [
        "pv2/units",
        "pv2/units/default",
        "app",
        "requisitionType/administration/searchResults",
        "requisitionType/administration/searchResults/\\d+",
        "CodingTemplate/\\d+/defaultSuppliers",
        "cfoDashboard/companies",
        "reports/filter/payment-term/autocomplete",
        "reports/filter/user/autocomplete",
        "reports/share/receiver/autocomplete",
        "reports/documentReasonCode/autocomplete",
        "payment-request/type/\\d+/\\d+/custom-fields",
        "payment-request/types/\\d+",
        "payment-request/references",
        "followupGroupUser",
        "RoutingTable/upload/\\d+",
        "cfoDashboard/paymentDateIntegrationEnabled",
        "cfoDashboard/cashflow/isAccessible",
        "cfoDashboard/apProcess/isAccessible",
        "invoiceimageusers/\\d+",
        "pv2/supplierConfiguration/company/\\d+/supplier",
        "reports/filter/label/autocomplete",
        "currency/forcompany",
        "pv2/freeTextItemContracts/\\d+",
        "pv2/freeTextItemContracts/getContract/\\d+",
        "pv2/freeTextItemUnits",
        "pv2/freeTextItemUnits/default",
        "pv2/freeTextItemUnits/getUnit/\\d+",
        "pv2/freeTextItemCurrencies/\\d+",
        "pv2/freeTextItemCurrencies/getCurrency/\\d+",
        "pv2/freeTextItemCurrencies/isSupplierCurrencyInList/\\d+/\\d+",
        "pv2/company/\\d+/suppliers",
        "pv2/company/\\d+/contracts",
        "pv2/itemGroups/\\d+/properties",
        "pv2/itemGroups/\\d+/properties/\\d+",
        "invoice/\\d+/dates",
        "supplier/\\d+/payment-term/invoice-dates",
        "HashFilesService/GetImageHashFilesWithNames",
        "contractsforinvoices/companyfilter",
        "contractsforinvoices/companyfilter/company",
        "authorizationGroupSupplier/search",
        "authorizationGroupCompany/search",
        "RefreshRemainingAmount",
        "categoryadministration/coding",
        "reports/connectionStatusType/autocomplete",
        "MentionSuggestions",
        "reports/purchaseRequisition/filter/orderType/autocomplete",
        "followupGroupSupplier",
        "catalogItemsAutocompleter",
        "temporaryDelegationsGadget/grid",
        "administration/purgeInvoices/configurations",
        "administration/purgeInvoices/configuration/\\d+",
        "administration/purgeInvoices/configuration",
        "administration/purgeInvoices/companies",
        "connectionStatus/GetVisible",
        "purchaseOrder/administration/gridEntries",
        "administration/login-options",
        "sendForReview",
        "pv2/company-role/\\d+/[^/]+/users",
        "pv2/company-role/\\d+/[^/]+/distributable-roles",
        "companyLabelAdministration",
        "companyLabelAdministration/\\d+",
        "companyLabelAdministration/companies",
        "contractbasedSupplierConfiguration/\\d+/\\d+",
        "administration/foureyes/companies/search",
        "permissionGroupsAdministration/companies",
        "permissionGroupsAdministration/companies/\\d+/permissionGroups",
        "purchase-order-document/payment-terms",
        "purchase-order-document/\\d+/payment-terms",
        "payment-request/\\d+/payment-terms",
        "orderbased-invoice/\\d+/payment-terms",
        "supplier-contract/\\d+/payment-terms",
        "supplier-contract/payment-terms",
        "supplier-contract/payment-term",
        "contractbasedCompanyConfiguration/\\d+",
        "contractbasedCompanyConfiguration/invoiceNumberMaximumLength/\\d+",
        "reports/adminCompany/autocomplete",
        "administration/customlist/suppliers",
        "resolutionConfigurationAdministration/resolutionconfigurations",
        "pv2/freeTextItemSuppliers/\\d+",
        "pv2/freeTextItemSuppliers/getSupplier/\\d+",
        "reports/filter/past-handler/autocomplete",
        "real-currencies",
        "pv2/company/\\d+/header",
        "pv2/companies",
        "pv2/companies/autocompleter",
        "pv2/companies/single",
        "pv2/company/\\d+/addresses",
        "administration/jobs/sendscheduledreportsjob",
        "document-approval-company-configuration/\\d+/response-message-simulation-allowance",
        "document-approval-company-configuration/\\d+/document-template-link",
        "document-approval-company-configuration/\\d+/main-attachment-mandatory-info/\\d+",
        "document-approval-company-configuration/\\d+/voucher-field-visibility/\\d+",
        "document-approval-company-configuration/\\d+/sub-type",
        "contractsforinvoices/contracts",
        "validate-payment-details-confirmation/[0-9a-z-]+/is-confirmed",
        "validate-payment-details-confirmation/[0-9a-z-]+/\\d+/confirm",
        "procurement/locations/IsLocationIdDuplicated",
        "procurement/locations/IsLocationNameDuplicatedWithinCompany",
        "procurement/locations/\\d+",
        "expense-invoice/\\d+/payment-terms",
        "links",
        "purchasing-system-final-integration/configuration/company/\\d+/enabled",
        "pv2/purchaseRequisition/supplierAutocompleter/company/\\d+/suppliers",
        "contractsforinvoices/supplierfilter",
        "contractsforinvoices/supplierfilter/supplier",
        "documentSourceManagement/documentProviders",
		"documentSourceManagement/documentFormats",
		"documentManagement/\\d+$",
		"documentManagement/documents",
		"documentManagement/has-invalidate-and-archive-permission",
        "accessManagement",
        "accessManagement/history",
        "accessManagement/users",
        "accessManagement/authorization",
        "contractCompanyConfiguration/recurringInvoices/dueDateHandling/\\d+",
        "contractCompanyConfiguration/recurringInvoices/active/\\d+",
        "contractCompanyConfiguration/\\d+",
        "payment-details-configuration/type/\\d+",
        "payment-details-configuration/shouldShowPaymentDetails/\\d+",
        "notifications/latest",
        "notifications/(mention|release)",
        "notifications/\\d+/read",
        "notifications/read/(mention|release)",
        "notifications",
        "documentValidationRuleAdministration/supplier/\\d+",
        "documentValidationRuleAdministration/label/\\d+",
        "documentValidationRuleAdministration/customlist",
        "documentValidationRuleAdministration/customlist/documentApproval",
        "documentValidationRuleAdministration/label/search/\\d+",
        "inactiveUsersGadget/search",
        "inactiveUsersGadget/searchReasons",
        "applicationData",
        "home/overview",
        "home/gadget/auto-distribution/expense-invoice",
        "home/gadget/overdue-open-invoices",
        "home/gadget/top-suppliers/by-invoice-volume",
        "home/gadget/documents-processing-time",
        "procurement/locations/\\d+/company/\\d+/autocompleter/users",
        "pv2/cart-state-validation/user/\\d+",
        "pv2/cart-state-validation/lines/\\d+",
        "pv2/cart-state-validation/details/\\d+",
        "pv2/freeTextItemCategories/\\d+",
        "pv2/freeTextItemCategories/getCategory/\\d+",
        "pv2/freeTextItemCategories/isDefaultInList/\\d+/\\d+",
        "pv2/freeTextFormFieldDocumentValidationRuleValues",
        "pv2/freeTextFormFieldDocumentValidationRuleValues/\\d+",
        "labels",
        "labels/company/\\d+",
        "labels/document/\\d+",
        "labels/task/\\d+",
        "labels/\\d+/document/\\d+",
        "labels/\\d+/document/\\d+",
        "administration/purgeInvoices/ispurgeloading",
        "administration/purgeInvoices/purgeresult",
        "administration/purgeInvoices/sendNotification",
        "administration/purgeInvoices/purge",
        "administration/purgeInvoices/company",
        "administration/purgeInvoices/purgeConfiguration",
        "administration/purgeInvoices/purgeHistoryLog",
        "pv2/supplierCategoryRestrictions/company/\\d+/autocomplete/suppliers",
        "pv2/supplierCategoryRestrictions/company/\\d+/permissionGroup/\\d+/categoryTreeView/root",
        "pv2/supplierCategoryRestrictions/company/\\d+/permissionGroup/\\d+/categoryTreeView/children",
        "pv2/supplierCategoryRestrictions/company/\\d+/permissionGroup/\\d+/categoryTreeView/search",
        "dashboard/dashboardGadgets/\\d+",
        "dashboard/availableGadgets",
        "dashboard/userDashboards",
        "dashboard/update",
        "pv2/itemGroups/\\d+/searchItems",
        "pv2/itemGroups/\\d+/items",
        "supplierReportsGadget/invoices-chart-data-by-month",
        "supplierReportsGadget/invoices-chart-data-by-year",
        "supplierReportsGadget/invoices-chart-data-by-total",
        "supplierReportsGadget/deviation-amounts-chart-data-by-month",
        "supplierReportsGadget/deviation-amounts-chart-data-by-year",
        "supplierReportsGadget/deviation-amounts-chart-data-by-total",
        "supplierReportsGadget/deviation-count-chart-data-by-month",
        "supplierReportsGadget/deviation-count-chart-data-by-year",
        "supplierReportsGadget/deviation-count-chart-data-by-total",
        "supplierReportsGadget/get-companies",
        "supplierReportsGadget/get-suppliers",
        "payment-term",
        "payment-term/\\d+",
        "pv2/itemGroups",
        "pv2/itemGroups/\\d+",
        "pv2/itemGroups/\\d+/activate",
        "pv2/itemGroups/\\d+/deactivate",
        "pv2/itemGroups/\\d+/editHeader",
        "pv2/itemGroups/\\d+/removeItems",
        "entities-to-link/recentlyViewed",
        "entities-to-link/search/[^/]+",
        "purchaseOrderComments/getCommentsForDocument",
        "purchaseOrderComments/getCommentsForOrders",
        "importConfiguration/\\d+/\\d+/integrationUpdateConfiguration",
        "importConfiguration/gridEntries",
        "importConfiguration/search",
        "importConfiguration/\\d+",
        "procurementCompanyConfiguration/\\d+/hasAccessToAdvancedSettings",
        "procurementCompanyConfiguration/\\d+",
        "procurement/locations/company/\\d+/deliveryAddresses/suggestedByCoding",
        "procurement/locations/company/\\d+/deliveryAddresses/suggestedByUser/\\d+",
        "procurement/locations/company/\\d+/deliveryAddresses/suggested",
        "categoryadministration/companies/\\d+",
        "categoryadministration/companies",
        "categoryadministration/companies/\\d+/configuration",
        "categoryadministration/companies/\\d+/dimensionValues/[0-9a-z-]+",
        "administration/customlist/configurations",
        "administration/customlist/configuration/\\d+",
        "administration/customlist/configuration",
        "user/administration/gridEntries",
        "user/administration/columnConfiguration"
    ];
}

export function getMigrationCompletedEndpointsList(): string[] {
    return [
        "taxIndicatorRules/documentTypes",
        "pv2/userEmail",
        "pv2/userEmail/getByUserId/\\d+",
        "goodsReceiptNote/grnForDeliveryLines",
        "pv2/resolutionConfiguration",
        "reports/role/autocomplete",
        "authorizationGroupCurrency",
        "CreditMemo/lines/\\d+",
        "reports/schedule/receiver/autocomplete",
        "bulkInbox/availableDocuments",
        "followupGroupCompany",
        "administration/customlist/companies",
    ];
}