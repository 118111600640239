///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Dashboard.DashboardContext/ActionAndControlTabCreator"/>
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as remediumRest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";

import * as ko from "knockout";

class ActionAndControlTabCreator {
    entity: any;
    AllActivityDefinitions: ko.ObservableArray<any>;
    AllDocutmentTypes: ko.ObservableArray<any>;
    SelectedActivityDefinition: ko.Observable<any>;
    SelectedDocumentType: ko.Observable<any>;
    constructor(entityWithContext:any){
        this.entity = entityWithContext.Entity;
        // Bindings
        this.AllActivityDefinitions = ko.observableArray([]);
        this.AllDocutmentTypes = ko.observableArray([]);

        this.SelectedActivityDefinition = ko.observable();
        this.SelectedDocumentType = ko.observable();

        //Actions
        remediumRest.get("remedium-api/workflowDefinitionDataService/userActivityDefinitions")
        .then(r=>r.json())
        .then((activityDefinitionCollection) => {
            for (let i = 0; i < activityDefinitionCollection.length; i++) {
                this.AllActivityDefinitions.push(activityDefinitionCollection[i].Name);
            }
        });

        this.AllActivityDefinitions.push(globalization.getLabelTranslation("#Core/AllActivitesDefinition"));

        rest.get("DocumentReferenceDataService", "documentTypes").done((documentTypesCollection) => {
            this.AllDocutmentTypes(documentTypesCollection);
        });
    }

    removeRow(context:any) {
        this.entity.Contexts.remove(context);
    }

    addRow() {
        const newContext = sync.getNew("Medius.Core.Entities.Dashboard.DashboardContext");
        newContext.SupportedDocument = ko.observable(this.SelectedDocumentType());
        newContext.SupportedActivityDefinition = this.SelectedActivityDefinition();


        this.entity.Contexts.push(newContext);
    }

    unwrap(object:any) {
        return ko.utils.unwrapObservable(object);
    }

    dispose() {
        this.entity = null;
        this.AllActivityDefinitions(null);
        this.AllDocutmentTypes(null);
        this.SelectedActivityDefinition(null);
        this.SelectedDocumentType(null);
    }
}

export = function (entityWithContext:any) {
    return new ActionAndControlTabCreator(entityWithContext);
};