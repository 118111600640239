/// <amd-module name="Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/openPrepaymentInvoicesAvailableBanner"/>
import * as React from "react";
import { Notification } from "@medius/ui-controls";
import { translate } from "../../lib/globalization";

export const OpenPrepaymentInvoicesAvailableBanner = () => {
    return <div className="open-prepayments__banner">
        <Notification data-testid="openprepayments-banner" feedbackType="info"
            title={translate("#PurchaseToPay/openPrepaymentInvoicesAvailableBanner/title")}
            text={translate("#PurchaseToPay/openPrepaymentInvoicesAvailableBanner/message")} />
    </div>;
};
