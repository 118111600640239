///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/WelcomeWidget" />
import * as React from "react";
import { IconDesignEyeslashFill, IconToggleSparkleFill, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { useCopilotContext } from "../hooks/useCopilotContext";


export const WelcomeWidget = () => {
    const { 
        isDocumentImport
    } = useCopilotContext();

    return (
        <div className="mediusAgent__welcomeWidgetContainer" data-testid="apa-copilot-operational">
            {!isDocumentImport &&
                <div className="mediusAgent__welcomeWidgetContainer__messageContainer">
                    <div className="mediusAgent__welcomeWidgetContainer__iconContainer">
                        <IconToggleSparkleFill />
                    </div>
                    <div className="mediusAgent__welcomeWidgetContainer__messageContainer__messageText">
                        <Text variant="heading3">{getLabelTranslation("#Core/tryOutPredefinedPrompts")}</Text>
                        <Text variant="body">{getLabelTranslation("#Core/agentWelcomeWidgetMessageTextPredefinedPrompts")}</Text>
                    </div>
                </div>
            }
            <div className="mediusAgent__welcomeWidgetContainer__messageContainer">
                <div className="mediusAgent__welcomeWidgetContainer__iconContainer">
                    <IconDesignEyeslashFill />
                </div>
                <div className="mediusAgent__welcomeWidgetContainer__messageContainer__messageText">
                    <Text variant="heading3">{getLabelTranslation("#Core/otherPeopleInYourOrganizationCannotSeeThisConversation")}</Text>
                    <Text variant="body">{getLabelTranslation("#Core/itsJustBetweenYouAndYourCopilot")}</Text>
                </div>
            </div>
        </div>
    );
};