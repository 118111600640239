/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/rest"/>
import { toAction } from "../lib/path";
import { ajax as ajaxRawCall } from "../lib/ajax";
import { getMigratedUrlIfNeeded} from "Core/Medius.Core.Web/Scripts/Helpers/migrationApisHelper";

function ajaxCall(
    service: string,
    resource: string | number,
    options: JQueryAjaxSettings = {},
    signal: AbortSignal = undefined,
    httpMethod: string = undefined,
    forceCache: boolean = true) {

    const settings = { ...options };
    if (httpMethod)
        settings.type = httpMethod;
    if (forceCache)
        settings.cache = true;

    const uri = createUrl(service, resource);
    return ajaxRawCall(uri, settings, signal);
}


export function createUrl(service: string, resource?: string | number) {
    const url = `${toAction("Rest", "~/Backend")}/${service}`;
    const urlWithResource = resource || resource === 0
    ? `${url}/${resource}`
    : url;
    const doNotAddBaseUrl = (s:string)=>s;
    return getMigratedUrlIfNeeded(urlWithResource,doNotAddBaseUrl);
}

/* @deprecated */
export function ajax(service: string, resource: string | number, options?: JQueryAjaxSettings, signal?: AbortSignal) {
    return ajaxCall(service, resource, options, signal);
}

export function get(service: string, resource?: string | number, options?: JQueryAjaxSettings, signal?: AbortSignal) {
    return ajaxCall(service, resource, options, signal, "GET", false);
}

export function post(service: string, resource: string | number, options?: JQueryAjaxSettings, signal?: AbortSignal) {
    return ajaxCall(service, resource, options, signal, "POST");
}

export function put(service: string, resource: string | number, options?: JQueryAjaxSettings, signal?: AbortSignal) {
    return ajaxCall(service, resource, options, signal, "PUT");
}

export function del(service: string, resource: string | number | undefined, options?: JQueryAjaxSettings, signal?: AbortSignal) {
    return ajaxCall(service, resource, options, signal, "DELETE");
}
