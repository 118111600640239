/// <amd-module name="Core/Medius.Core.Web/Scripts/errorPages/PunchOut"/>
import * as React from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";
import { translate } from "../lib/globalization";

export default function PunchoutErrorPage() {
    return (
        <Card>
            <div className="card-content">
                <div className="card-message">
                    {translate("#Core/punchoutRequestError")}
                </div>
            </div>
        </Card>
    );
}
