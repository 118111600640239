/// <amd-module name="Core/Medius.Core.Web/Scripts/Helpers/migrationApisHelper"/>
import { getMigratedRpcEndpointsList,  getEnabledMigratedWebApiEndpointsList, getApplicationVersion, MigratedWebApiEndpoint } from "Core/Medius.Core.Web/Scripts/appConfig";
import { getAvailableMigratedWebApiEndpointsList, getMigrationCompletedEndpointsList } from "Core/Medius.Core.Web/Scripts/Helpers/migrationApisAvailableEndpointsList";
import { getUrlWithBase } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

export function isMigratedRpcEndpoint(endpoint : string) : boolean {
    const migratedList = getMigratedRpcEndpointsList();
    const endpointWithVersion = migratedList.find(s => s.startsWith(endpoint));

    if(!!endpointWithVersion){
        const endpointVersion = endpointWithVersion.split("#")[1];
        const currentAppVersion = getApplicationVersion();

        return endpointVersion === undefined 
        || endpointVersion === null 
        || isSameOrNewerVersion(currentAppVersion ,endpointVersion);
    }

    return false;
}

function isRemediumCall(url:string){
    return url.indexOf("remedium-api")>-1;
}

function isMigrationCompletedFor(searchedEndpoint:string): boolean{
    if(!searchedEndpoint)
        return false;
    return getMigrationCompletedEndpointsList()
        .some(migratedEndpoint=>fullyMatches(searchedEndpoint, migratedEndpoint));
}

export function getMigratedUrlIfNeeded(url: string, addBase: (s:string)=>string = getUrlWithBase): string {
    let urlStart = "";
    let urlEnd =  url;
    const mediusFlowBackendUriPart = "Backend/Rest/";
    
    if (url.includes(mediusFlowBackendUriPart)) {
        urlStart = url.split(mediusFlowBackendUriPart)[0];
        urlEnd = url.split(mediusFlowBackendUriPart)[1];
    }
    
    if(isRemediumCall(urlEnd))
        return addBase(`${urlStart}${urlEnd}`);
    
    if(isMigrationCompletedFor(urlEnd) || isMigratedWebApiEndpoint(urlEnd))
        return addBase(`${urlStart}remedium-api/${urlEnd}`);


    return addBase(url);
}

export function isMigratedWebApiEndpoint(endpoint : string): boolean {
    if(!endpoint)
        return false;
    
    const endpointWithVersion = getEndpointEntryFromMigratedList(endpoint);
    if(!endpointWithVersion)
        return false;

    return isEndpointVersionValid(endpointWithVersion);

}

export function isEndpointVersionValid(endpointWithVersion:MigratedWebApiEndpoint){
    const endpointVersion = endpointWithVersion.version;
    const currentAppVersion = getApplicationVersion();
    
    return endpointVersion === undefined 
    || endpointVersion === null 
    || isSameOrNewerVersion(currentAppVersion ,endpointVersion);
}

function fullyMatches(searchedEndpoint: string, fixedEndpoint:string):boolean{
    const searchedEndpointWithoutQueryParams = searchedEndpoint.split('?')[0];
    return new RegExp(`^${fixedEndpoint}/?$`, 'i').test(searchedEndpointWithoutQueryParams);
}

export function getEndpointEntryFromMigratedList(searchedEndpoint:string):MigratedWebApiEndpoint|undefined{
    const availableList = getAvailableMigratedWebApiEndpointsList();
    const isAvailable = availableList.some(availableEndpoint=>fullyMatches(searchedEndpoint,availableEndpoint));
    if (!isAvailable) return undefined;

    const enabledList = getEnabledMigratedWebApiEndpointsList();
    const endpointWithVersion = enabledList.find(enabledEndpoint=>fullyMatches(searchedEndpoint,enabledEndpoint.endpoint));
    return endpointWithVersion;
}

export function isSameOrNewerVersion(version1: string, version2: string): boolean {
    const arr1 = version1.split('.').map(Number);
    const arr2 = version2.split('.').map(Number);

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] > arr2[i]) {
            return true;
        } else if (arr1[i] < arr2[i]) {
            return false;
        }
    }

    return true;
}