///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights"/>

import { trackEvent } from "Core/Medius.Core.Web/Scripts/appInsights";

export function trackInteractionEvent(interactionType: MediusCopilotInteractionType, taskId:number = null, additionalData: string = null)
{
    trackEvent({
        name: `apa-ai-agent-interaction-${interactionType}`,
        properties: {
            additionalData: additionalData,
            TaskId : taskId
        }
    });
}

export enum MediusCopilotInteractionType {     
    //event on close copilot sidebar by clicking on x button
    CloseSidebar = "close-sidebar",
    //event on close notification window by clicking on x button
    CloseNotification = "close-notification",
    //event on open copilot sidebar by clicking on copilot button
    OpenSidebarWithCopilotButton = "open-sidebar-with-copilot-button",
    //event on open copilot sidebar by clicking on predefined question in notification window
    OpenSidebarWithNotification = "open-sidebar-with-notification",
    //event on open notification window
    OpenNotification = "open-notification",
    //event on click on predefined question in the notification window
    NotificationWindowButtonClick = "notification-window-button-click",
    //event on click to hide the notification window
    NotificationWindowHideoutReason = "notification-window-hideout-reason",
    //event on click on button (translate or summarize) in invoice image tooltip
    InvoiceImageTooltipButtonClick = "invoice-image-tooltip-button-click"
}