///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/TimeoutErrorMessage" />
import { 
    FlexLayout, 
    Text,
    IconStatusWarningFill
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";

export const TimeoutErrorMessage = () => {
    return (
        <FlexLayout
             direction="row" 
             wrapItems={false}
             className="mediusAgent__timeoutErrorMessageContainer"
         >
            <IconStatusWarningFill className="mediusAgent__timeoutErrorMessageContainer__icon"/>
            <Text variant="body">    
                <span className="mediusAgent__timeoutErrorMessageContainer__header">
                    {getLabelTranslation("#Core/weAreExperiencingSomeTechnicalIssues")}&nbsp;
                </span>
                {getLabelTranslation("#Core/someDataMayNotBeAvailableButYouCanStillUseMediusCopilot")}
            </Text>     
        </FlexLayout>    
    );
};

