/* eslint-disable react/no-unknown-property */
///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton"/>
import { CopilotImage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/images/CopilotImage";
import * as React from "react";

interface ICopilotButtonProps {
    onClick: () => void,
    label: string,
    "data-testid": string,
    isMainCopilotButton: boolean;
}

export function CopilotButton(props: ICopilotButtonProps) {
    const styles = props.isMainCopilotButton ? 
        {} : 
        { border: "1px solid black", margin: "5px", color: "black", padding: "0px 8px" };

    return ( 
        <button 
            data-testid={props["data-testid"]} 
            onClick={props.onClick} 
            className={`copilotButton${props.isMainCopilotButton ? " animate" : ""}`}
            style={styles}
            close-ai-agent-modal="false"
        >
            <CopilotImage close-ai-agent-modal="false"/>
            <span style={props.isMainCopilotButton ? {} : { fontSize: "13px" }} close-ai-agent-modal="false">
                {props.label}
            </span>
        </button>
    );
}