/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/fetch/mediusFlowMigrationRest"/>
import * as _ from "underscore";
import { getUrlWithBase } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { addToHeaders } from "Core/Medius.Core.Web/Scripts/Medius/core/antiForgeryToken";
import * as noMSDatesValidator from "Core/Medius.Core.Web/Scripts/lib/development/noMSDatesValidator";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { validateVersionFromHeaderFetch } from "Core/Medius.Core.Web/Scripts/lib/validation/validators/versionValidator";
import { isUIErrorMessagesLoggingToAppInsightsEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

type Data = any & {
    signal?: AbortSignal;
};

export async function post<T = any>(url: string, data?: Data, withDefaultCallbacks: boolean = true): Promise<any> {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "POST",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);

    const path = getUrlWithBase(url);
    const requestDate = new Date();

    return withDefaultCallbacks 
        ? defaultHandler<T>(path, options) 
        : fetch(path, options)
            .then((response) => handleVersionValidator(response, requestDate));
}

function defaultHandler<T = any>(path: string, options: any) {
    const requestDate = new Date();
    return fetch(path, options)
        .then((response) => handleVersionValidator(response, requestDate))
        .then((response) => handleResponseErrors(response, requestDate))
        .then(convertToJson)
        .then(validateDatesFormat) as Promise<T>;
}

export async function postFormData<T = any>(url: string, data: FormData, signal?: AbortSignal) {
    const options = {
        headers: {
            "Accept": "application/json",
        },
        method: "POST",
        credentials: "same-origin" as RequestCredentials,
        body: data
    };

    addToHeaders(options);
    addAbortSignal(options, data, signal);

    const path = getUrlWithBase(url);
    const requestDate = new Date();

    return fetch(path, options)
        .then((response) => handleVersionValidator(response, requestDate))
        .then((response) => handleResponseErrors(response, requestDate))
        .then(convertToJson)
        .then(validateDatesFormat) as Promise<T>;
}

export async function put<T = any>(url: string, data?: Data) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "X-Json-Preserve-References": "true"
        },
        dataType: "text",
        method: "PUT",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);

    const path = getUrlWithBase(url);
    const requestDate = new Date();

    return fetch(path, options)
        .then((response) => handleVersionValidator(response, requestDate))
        .then((response) => handleResponseErrors(response, requestDate))
        .then(convertToJson)
        .then(validateDatesFormat) as Promise<T>;
}

export async function del<T = any>(url: string, data?: Data) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "DELETE",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);

    const path = getUrlWithBase(url);
    const requestDate = new Date();

    return fetch(path, options)
        .then((response) => handleVersionValidator(response, requestDate))
        .then((response) => handleResponseErrors(response, requestDate))
        .then(convertToJson)
        .then(validateDatesFormat) as Promise<T>;
}

export async function get<T = any>(query: string, data: Data = {}) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "GET",
        credentials: "same-origin" as RequestCredentials
    } as any;

    addAbortSignal(options, data);

    let path = getUrlWithBase(`remedium-api/mediusflow/${query}`);
    const requestDate = new Date();

    if (!_.isEmpty(data)) {
        path += "?" + Object.keys(data)
            .filter((key: any) => {
                if (Array.isArray(data[key]) && data[key].length === 0) {
                    return false;
                }
                return data[key] !== null;
            })
            .map((key: any) => {
                if (Array.isArray(data[key])) {
                    return data[key].map((value: any) => `${key}=${value}`).join("&");
                } else {
                    return `${key}=${data[key]}`;
                }
            })
            .join("&");
    }

    return fetch(path, options)
        .then((response) => handleVersionValidator(response, requestDate))
        .then((response) => handleResponseErrors(response, requestDate))
        .then(convertToJson)
        .then(validateDatesFormat) as Promise<T>;
}

export function aborted(onRejectedReason: any) {
    return onRejectedReason.name == "AbortError";
}

function addAbortSignal(options: any, data?: Data, signal?: AbortSignal) {
    if (data?.signal) {
        options.signal = data.signal;
        delete data.signal;
    }
    else if (isNotNullOrUndefined(signal)) {
        options.signal = signal;
    }
}

function handleVersionValidator(response: Response, requestDate: Date) {
    validateVersionFromHeaderFetch(response.headers, response.url, requestDate);
    
    return response;
}

function handleResponseErrors(response: Response, requestDate: Date) {
    // Response handling based on https://stackoverflow.com/questions/41103360/how-to-use-fetch-in-typescript and https://stackoverflow.com/questions/70772238/javascript-fetch-api-use-custom-error-message
    if (!response.ok) {
        const errorText = response.clone().text();

        if (isUIErrorMessagesLoggingToAppInsightsEnabled()) {
            let duration = 0;
            if (requestDate) {
                duration = new Date().getTime() - requestDate.getTime();
            }
            
            //If response is taking more than configured amount (and its demo environment) we should treat it as silient error
            if (duration > 30000) {
                console.warn("Response not OK but response time longer than 30 seconds will be handled as a silent error." + response.url);
                
                return errorText;
            }
        }
        
        // response needs to be cloned before executing .text(), as subsequent calls to e.g. resonse.json() would result in error. See https://stackoverflow.com/questions/53511974/javascript-fetch-failed-to-execute-json-on-response-body-stream-is-locked
        return errorText.then(text => {
            const error = new Error(text);
            // tslint:disable-next-line:no-string-literal
            (error as any)["response"] = response;
            throw error;
        });
    }
    return response.text();
}

function convertToJson(textResponse: string) {
    // we are doing it by handling response as text and then parsing it to null to correctly handle cases when service is returning null
    if (textResponse === "") {
        return null;
    }
    return JSON.parse(textResponse);
}

function validateDatesFormat(json: any) {
    noMSDatesValidator.assertNoMicrosoftFormatDates(json);
    return json;
}
