///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PredefinedQuestionButtons"/>
import * as React from "react";
import { displayedPredefinedQuestionTranslationTags } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Button } from "@medius/ui-controls";
import { IPredefinedQuestion } from "../agentInterfaces";

interface PredefinedQuestionsProps {
    disabled: boolean,
    predefinedQuestionsList: IPredefinedQuestion[],
    onPredefinedQuestionClick: (predefinedQuestion: string) => Promise<void>,
}

export const PredefinedQuestions = ({
    onPredefinedQuestionClick,
    predefinedQuestionsList,
    disabled
}: PredefinedQuestionsProps) => {
    const [displayedPredefinedQuestions, setDisplayedPredefinedQuestions] = React.useState<IPredefinedQuestion[]>([]);

    React.useEffect(() => {
        const filteredPredefinedQuestions = predefinedQuestionsList.filter(question => displayedPredefinedQuestionTranslationTags.includes(question.value));
        setDisplayedPredefinedQuestions(filteredPredefinedQuestions);
    },[predefinedQuestionsList, setDisplayedPredefinedQuestions]);

    return displayedPredefinedQuestions.length > 0 ? 
        <div className="mediusAgent__promptInput__predefinedQuestions" data-testid="predefinedQuestions-container">
            {displayedPredefinedQuestions.map((question, index) => 
                <div key={index} className={"mediusAgent__promptInput__predefinedQuestions__questionButtonContainer"}>
                    <Button
                        label={getLabelTranslation(question.value)}
                        height="small"
                        variant="tertiary"
                        onClick={() => onPredefinedQuestionClick(question.value)}
                        disabled={disabled}
                        data-testid={`predefinedQuestion-${question.categorizationId}`}
                    />
                </div>
            )}
        </div> : 
    <></>;
};