///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/dataProvider"/>
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export class CompanyDataProvider extends DataProvider {
    constructor() {
        super();
    }

    callServer(phrase:string, limit:number, offset: number, company:any, includeVirtual:boolean, includeReal:boolean, includeInactive:boolean) {
        const params = {
            phrase: phrase,
            detachMode: 'Autocompleter',
            contextCompanyId: this.getId(company),
            limit: limit,
            offset: offset,
            includeVirtual: includeVirtual,
            includeReal: includeReal,
            includeInactive: includeInactive
        };

        return rpc.request("CompanySearchService", "SearchCompanyByPhrase", params)
            .fail(function (data) {
                backendErrorHandler.handleAnyError(data);
            });
    }

    search(searchText:string, maxResults:number, offset:number, options:any) {
        return this.callServer(searchText, maxResults, offset, options.company, options.includeVirtual, options.includeReal, options.includeInactive);
    }
}

export function create() {
    return new CompanyDataProvider();
}