///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/DimensionsTemplate"/>
import { Checkbox, LocalizationContext, NumberBox, StringMap, TextBox } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { getCultureCode } from "../../lib/utils/user";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";

export interface IDimensionsTemplateProps {
    dimensions: IDimensionProperty[];
    limitedLengthDimensions: IDimensionProperty[];
}

export interface IDimensionProperty extends IDimensionData {
    onChange: (updatedDimension: IDimensionData) => void;
    translatedDescription: string;
}

export interface IDimensionData {
    isActive: boolean;
    name: string;
    shortName: string;
    isOverridden: boolean;
    maximumLength?: number;
}

export interface IAction {
    type: string;
    payload: any;
}

interface IDimensionTableRowProps {
    dimension: IDimensionProperty;
    isFreeText: boolean;
    testId: string;
}

function reducer(state: IDimensionData, action: IAction) {
    switch (action.type) {
        case 'updateName':
            return {
                ...state,
                shortName: state.shortName === state.name ? action.payload : state.shortName,
                name: action.payload
            };
        case 'updateIsActive':
            return {
                ...state,
                isActive: action.payload
            };
        case 'updateIsOverridden':
            return {
                ...state,
                isOverridden: action.payload
            };
        case 'updateMaximumLength':
            return {
                ...state,
                maximumLength: action.payload
            };
        case 'updateShortName':
            return {
                ...state,
                shortName: action.payload
            };
    }
}

const DimensionsTableRow = ({
    dimension,
    isFreeText,
    testId }: IDimensionTableRowProps) => {
    const [state, dispatch] = React.useReducer(reducer, {
        isActive: dimension.isActive,
        name: dimension.name,
        shortName: dimension.shortName,
        isOverridden: dimension.isOverridden,
        maximumLength: dimension.maximumLength
    } as IDimensionData);

    React.useEffect(() => {
        dimension.onChange(state);
    }, [state]);

    const isDisabled = () => !state.isOverridden;

    return (
        <tr>
            <td>
                <Checkbox checked={state.isActive} disabled={isDisabled()} onChange={({ value }) => dispatch({ type: 'updateIsActive', payload: value })} data-testid={`${testId}_isActive`} />
            </td>
            <td>
                <TextBox value={state.name} disabled={isDisabled()} onChange={({ value }) => dispatch({ type: 'updateName', payload: value })} data-testid={`${testId}_name`} />
            </td>
            <td>
                <TextBox value={state.shortName} disabled={isDisabled()} onChange={({ value }) => dispatch({ type: 'updateShortName', payload: value })} data-testid={`${testId}_shortName`} />
            </td>
            <td>
                {isFreeText && <NumberBox value={state.maximumLength} disabled={isDisabled()} onChange={({ value }) => dispatch({ type: 'updateMaximumLength', payload: value })} data-testid={`${testId}_maximumLength`} />}
            </td>
            <td>
                <label>
                    <Checkbox
                        checked={state.isOverridden}
                        label={dimension.translatedDescription}
                        description={getLabelTranslation("#Core/inheritingPropertyHelpText")}
                        onChange={({ value }) => dispatch({ type: 'updateIsOverridden', payload: value })}
                        data-testid={`${testId}_isOverridden`}
                    />
                </label>
            </td>
        </tr>
    );
};

export const DimensionsTemplate = (props: IDimensionsTemplateProps) => {
    const { dimensions, limitedLengthDimensions } = props;

    return (
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>{getLabelTranslation("#Enterprise/active")}</th>
                        <th>{getLabelTranslation("#Enterprise/name")}</th>
                        <th>{getLabelTranslation("#Enterprise/shortName")}</th>
                        <th>{getLabelTranslation("#Core/maximumLength")}</th>
                        <th>{getLabelTranslation("#Core/inheritedFrom")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={5}>{getLabelTranslation("#Enterprise/dimensions")}</td>
                    </tr>
                    {dimensions.map((dimension, index) => (

                        <DimensionsTableRow
                            key={`dimension_${index + 1}`}
                            testId={`dimension_${index + 1}`}
                            dimension={dimension}
                            isFreeText={false} />
                    ))}
                    <tr>
                        <td colSpan={5}>{getLabelTranslation("#Enterprise/FreeTextDimension")}</td>
                    </tr>
                    {limitedLengthDimensions.map((dimension, index) => (
                        <DimensionsTableRow
                            key={`limitedLengthDimension_${index + 1}`}
                            testId={`limitedLengthDimension_${index + 1}`}
                            dimension={dimension}
                            isFreeText={true} />
                    ))}
                </tbody>
            </table>
        </LocalizationContext.Provider>
    );
};
