/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/notification"/>

import * as moment from "moment";
import * as mode from "Core/Medius.Core.Web/Scripts/lib/development/mode";
import { sanitize } from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import { successIcon, infoIcon, errorIcon } from "Core/Medius.Core.Web/Scripts/Medius/core/notificationIcons";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { trackEvent } from "Core/Medius.Core.Web/Scripts/appInsights";
import { isUIErrorMessagesLoggingToAppInsightsEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

const stack_bottom_middle = { 'dir1': 'up', 'firstpos1': 0, 'spacing1': 0 };
const customClass = "custom-pnotify-stack-bar-bottom custom-pnotify-message";
const moduleButtons = "custom-pnotify-icon icon-white glyphicons ";


// Note: This is an extension, to help with stability of our Selenium tests - where we need to
//       assert what notifications were shown, possibly some time after they already disappeared.
//       It is expected by: https://dev.azure.com/MediusDevOps/APAutomation/_git/APA?path=%2FSelenium%2FWeb%2FCommon%2FCommonHelpers%2FAlerts.cs&version=GT11.115.0.0&_a=contents
if (!(window as any).shownNotifications) {
    (window as any).shownNotifications = [];
}

const traceShown = (type: any, timestamp: any, title: any, message: any): void => {
    (window as any).shownNotifications
        .push(type + ":" + timestamp.valueOf() + "#" + message + "|" + title);
};


const formattedTimestamp = (timestamp: Date) =>
    `<div class="span3 custom-pnotify-current-date">
        ${moment(timestamp).utc().format('l, HH:mm:ss')} (UTC)
    </div>`;

const formattedMessage = (message: string) =>
    message.length <= 1000
        ? `<div class="span8 no-left-margin-text ws-pre-line">${message}</div>`
        : `<div class="span8 no-left-margin-text custom-pnotify-large-error">
                <textarea class="bootstrap-legacy-textarea ws-pre-line">${message}</textarea>
                <div class="custom-pnotify-large-error-hide-scroll"></div>
            </div>`;

const extendedMessage = (timestamp: Date, message: string) =>
    `<div class="row-fluid ws-normal">
        <div class="span1"></div>
        ${formattedMessage(message)}
        ${formattedTimestamp(timestamp)}
    </div>`;

const extendedMessageWithIcon = (timestamp: Date, message: string, icon: string) =>
    `<div class="row-fluid ws-normal">
        <div class="span1 center-align-icon">${icon}</div>
        ${formattedMessage(message)}
        ${formattedTimestamp(timestamp)}
    </div>`;

const extendedTitle = (title: string, icon: string) =>
    `<div class="row-fluid ws-normal">
        <div class="span1 center-align-icon">${icon}</div>
        <div class="span8 no-left-margin-text">${title}</div>
    </div>`;


const resolveIconFor = (type: string): string => {
    switch (type) {
        case "success": return successIcon;
        case "info": return infoIcon;
        default: return errorIcon;
    }
};

const resolveDelayFor = (type: string): number => {
    switch (type) {
        case "success": return 1500;
        case "error": return 7000;
        default: return 3000;
    }
};

function showCustomStyleMessage(type: string, title: string | null | undefined, message: string) {
    const hasNoTitle = isNullOrUndefined(title);
    if (hasNoTitle && !mode.isProduction()) {
        const title = "Notification with no title";
        console.error(`${title}: ${message}`);
    }

    const timestamp = new Date();
    traceShown(type, timestamp, title, message);

    const icon = resolveIconFor(type);
    const delay = resolveDelayFor(type);

    const titleRow = !hasNoTitle
        ? extendedTitle(sanitize(title), icon)
        : false;

    const sanitizedMessage = sanitize(message);
    const textRow = !hasNoTitle
        ? extendedMessage(timestamp, sanitizedMessage)
        : extendedMessageWithIcon(timestamp, sanitizedMessage, icon);


    (window as any).PNotify.defaults.delay = delay;
    (window as any).PNotify.alert({
        title: titleRow,
        titleTrusted: true,
        text: textRow,
        textTrusted: true,
        type: type || false,
        styling: {},
        addClass: customClass,
        icon: false,
        cornerClass: "ui-pnotify-sharp",
        width: "99%",
        hide: true,
        shadow: false,
        stack: stack_bottom_middle,
        modules: {
            Buttons: {
                classes: {
                    closer: moduleButtons + 'glyphicons-remove',
                    pinUp: moduleButtons + 'icon-white glyphicons glyphicons-pause',
                    pinDown: moduleButtons + 'icon-white glyphicons glyphicons-play'
                }
            }
        }
    });
}

export function error(message: string, title?: string) {
    if (isUIErrorMessagesLoggingToAppInsightsEnabled()) {
        //Log error to app insights as custom event
        trackEvent({
            name: `apa-error-notification`,
            properties: {
                message: message,
                title: title              
            }
        });
    }

    showCustomStyleMessage("error", title, message);
}

export function info(message: string, title?: string) {
    showCustomStyleMessage("info", title, message);
}

export function success(message: string, title?: string) {
    showCustomStyleMessage("success", title, message);
}
