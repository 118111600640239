///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/advanced" />

import * as ko from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";

import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as document from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/document";
import * as labelsRepository from "Core/Medius.Core.Web/Scripts/Medius/core/labels/labelsRepository";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { Field as TaskField } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/fields";
import { Query, DocumentCondition, DocumentCriteria, TaskCondition } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/query";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";

const service = "InboxManager";
const tasksService = "TasksService";
const generalDocType = "Medius.Data.Document";
const showOnHoldAndNotOnHold = "all";
const showOnlyOnHold = "onlyOnHold";
const showOnlyNotOnHold = "onlyNotOnHold";
const hideInReview = "hideInReview";
const onlyInReview = "onlyInReview";
const onlyAfterReview = "onlyAfterReview";

export class Advanced {
    Documents = ko.observableArray([]);
    Name = ko.observable(null);

    isAdvancedFilterActive = ko.observable(false);
    DocsChosen = ko.observable(null);
    AvailableDocuments = ko.observableArray([]);
    SearchableLabels = ko.observableArray([]);
    SearchedLabels = ko.observableArray([]);
    DescriptionField: any = ko.observable();
    InReviewField: any = ko.observable();
    TaskFields = ko.observableArray([]);
    DescriptionTaskFields = ko.observableArray([]);
    SearchableDescriptions = ko.observableArray([]);
    UniqueTaskNames = ko.computed(() => {
        return _.uniq(this.SearchableDescriptions()
            .map((description) => {
                return description.Name;
            }));
    });

    SavedSearch = ko.observable(null);
    IsLoading = ko.observable(false);
    OnlyDocumentsWithoutLabels = ko.observable(false);

    AllAvailableRiskTypes = ko.observableArray<string>(['DocumentsWithActiveRiskFactors', 'DocumentsWithHandledRiskFactors', 'DocumentsWithoutRiskFactors']);
    RiskStatusChosen = this.AllAvailableRiskTypes;

    FraudModuleActive = ko.observable(false);

    private fraudModuleUnsubscribe = store.subscribe(() => {
        this.loadFraudModuleActive();
    });

    private loadFraudModuleActive() {
        this.FraudModuleActive(store.getState().accesses?.enabledModules?.includes(Module.FraudAndRiskProtection));
    }

    LabelsEnabled = ko.computed(() => {
        return !this.OnlyDocumentsWithoutLabels();
    });
    WithLabels = ko.computed(() => {
        if (!this.OnlyDocumentsWithoutLabels() && this.SearchedLabels().length === 0) {
            return false;
        }
        return true;
    });
    LabelChosen = ko.observable(null);
    DescriptionChosen = ko.observable(null);
    InReviewChosen = ko.observable(false);

    OnHoldConditionChosen = ko.observable("all");
    InReviewConditionChosen = ko.observable("all");
    DocsChosenSub: any;

    descriptionCache: any[];

    constructor() {
        //init
        // generate fields for task conditiw, sinon
        this.DescriptionField = new TaskField(globalization.getLabelTranslation("#Core/taskDescription"),
            "Medius.Data.Task", "Task.Description", "System.String");
        this.InReviewField = new TaskField(globalization.getLabelTranslation("#Core/inReview"),
            "Medius.Core.Entities.Workflow", "Task.InReview", "System.Boolean");
        this.TaskFields.push(this.DescriptionField);
        this.TaskFields.push(this.InReviewField);

        // load list of configured and available documents
        _.delay(() => {
            this.loadAvailableDocuments();
            this.loadLabels();
        }, 1);
        //initDocsChosenSub
        this.DocsChosenSub = this.DocsChosen.subscribe(() => {
            this.updateDocuments();
        });

        //others
        this.LabelChosen.subscribe(() => {
            this.updateLabels();
        });

        this.DescriptionChosen.subscribe(() => {
            this.updateDescriptions();
        });
    }

    init() {
        // generate fields for task conditiw, sinon
        this.DescriptionField = new TaskField(globalization.getLabelTranslation("#Core/taskDescription"),
            "Medius.Data.Task", "Task.Description", "System.String");
        this.InReviewField = new TaskField(globalization.getLabelTranslation("#Core/inReview"),
            "Medius.Core.Entities.Workflow", "Task.InReview", "System.Boolean");
        this.TaskFields.push(this.DescriptionField);
        this.TaskFields.push(this.InReviewField);

        // load list of configured and available documents
        _.delay(() => {
            this.loadAvailableDocuments();
            this.loadLabels();
        }, 1);
    }

    initDocsChosenSub() {
        this.DocsChosenSub = this.DocsChosen.subscribe(() => {
            this.updateDocuments();
        });
    }

    async loadTaskDescriptions() {
        if (this.descriptionCache && this.descriptionCache.length > 0) {
            this.SearchableDescriptions(this.descriptionCache);
            return;
        }

        this.IsLoading(true);
        await rpc.lightApi(tasksService, "GetTaskDescriptionsForAutocompleter")
            .done((descriptions) => {
                const descs = descriptions
                    .map((desc: any) => {
                        return {
                            Tag: desc,
                            Name: globalization.getLabelTranslation(desc)
                        };
                    })
                    .sort((a: any, b: any) => { //[CHECK] might be of type number
                        if (a.Name > b.Name) {
                            return 1;
                        }
                        return -1;
                    });

                this.descriptionCache = descs;
                this.SearchableDescriptions(descs);
                this.IsLoading(false);
            }).fail(function (jqXhr) {
                backendErrorHandler.handleAnyError(jqXhr);
            });
    }

    loadLabels() {
        this.SearchableLabels = labelsRepository.getAllLabelsObservableArray();
    }

    serializeQuery() {
        const query = Query();
        let hasInvalidCondition = false;
        let taskField;

        query.Name = this.Name();

        //DESCRIPTION FIELD
        if (isNotNullOrUndefined(this.DescriptionTaskFields()) && this.DescriptionTaskFields().length > 0) {
            taskField = TaskCondition(this.DescriptionField.Property, this.DescriptionTaskFields().join(", "));
            query.Task.push(taskField);
        }

        //IN REVIEW FIELD
        const inReviewCondition = this.mapRadioToInReviewCondition(this.InReviewConditionChosen());

        //DOCUMENTS
        const onHoldCondition = this.mapRadioToDocumentsOnHold(this.OnHoldConditionChosen());

        _.each(this.Documents(), (doc) => {
            const d = DocumentCriteria(doc.FullName);

            _.each(doc.SearchableFields(), (docField) => {
                const conditionName = ko.unwrap(docField.InboxFilterConditionName);
                const c = DocumentCondition(docField.Id, docField.Values[0].Value(), null, conditionName);
                c.ValueSource = docField.Property;

                if (docField.Values.length === 2) {
                    c.To = docField.Values[1].Value();

                    if (isNotNullOrUndefined(c.From) && isNotNullOrUndefined(c.To) && c.From > c.To) {
                        notification.error(globalization.getLabelTranslation("#Core/searchDateFromAfterDateTo"));
                        hasInvalidCondition = true;
                    }
                }

                if (c.From !== null || c.To !== null) {
                    //if we recognize the entity, id is sent to filter
                    if (_.isObject(c.From) && isNotNullOrUndefined(c.From.$type)) {
                        c.From = koUtils.unpack(c.From.Id);
                    }

                    d.Conditions.push(c);
                }
            });

            d.WithLabels = this.WithLabels();
            d.OnHoldCondition = onHoldCondition;
            d.InReviewCondition = inReviewCondition;
            d.RiskStatusChosen = this.RiskStatusChosen();

            query.Documents.push(d);
        });

        if (hasInvalidCondition) {
            return null;
        }

        let generalDocCriteria = _.find(query.Documents, function (doc) {
            return doc.Type === generalDocType;
        });

        if (generalDocCriteria == null) {
            generalDocCriteria = DocumentCriteria(generalDocType);
            query.Documents.push(generalDocCriteria);
        }

        generalDocCriteria.WithLabels = this.WithLabels();
        generalDocCriteria.Labels = this.serializeLabels();
        generalDocCriteria.OnHoldCondition = onHoldCondition;
        generalDocCriteria.InReviewCondition = inReviewCondition;
        generalDocCriteria.RiskStatusChosen = this.RiskStatusChosen();
        return JSON.stringify(query);
    }
    mapRadioToInReviewCondition(value: string) {
        const inReviewConditionValueMap = {
            all: null,
            hideInReview: hideInReview,
            onlyInReview: onlyInReview,
            onlyAfterReview: onlyAfterReview
        } as any;
        return inReviewConditionValueMap[value];
    }

    serializeLabels() {
        if (this.WithLabels() === false) {
            return [];
        }

        return this.SearchedLabels();
    }

    // The database saved value is a nullable boolean for some reason, this workaround ensures saved fitlers don't break
    mapDocumentsOnHoldToRadio(value: boolean) : string {
        if (value === null) {
            return showOnHoldAndNotOnHold;
        }
        if (value === true) {
            return showOnlyOnHold;
        }
        if (value === false) {
            return showOnlyNotOnHold;
        }
    }

    mapRadioToDocumentsOnHold(value: string) : boolean {
        const onHoldValueMap = {
            all: null,
            onlyOnHold: true,
            onlyNotOnHold: false
        } as any;
        return onHoldValueMap[value];
    }

    // Not all historically saved filters have a value here, this workaround ensures saved fitlers don't break
    mapInReviewConditionToRadio(value: string) {
        return value || "all";
    }
    
    mapRiskStatusChosen(value: string[]) {
        return value || this.AllAvailableRiskTypes();
    }

    async mapDescriptionTagsToNames(chosenTags: string[]) {
        const chosenNames: string[] = [];

        if (this.SearchableDescriptions().length === 0) {
            await this.loadTaskDescriptions();
        }

        this.SearchableDescriptions()
        .forEach((description) => {
            if (chosenTags.indexOf(description.Tag) !== -1) {
                chosenNames.push(description.Name);
            }
        });

        return chosenNames;
    }

    async parseQuery(savedSearch: any) {
        const query = JSON.parse(koUtils.unpack(savedSearch.SerializedQuery));

        if (!query) {
            return;
        }

        // reset state
        this.reset();

        // Basics
        this.Name(query.Name || null);
        this.SavedSearch(savedSearch);

        // Task
        _.each(query.Task, (taskParam) => {
            const field = this.findTaskField(taskParam.Property);
            field.updateField(taskParam.From, taskParam.To);
        });

        // Tags are stored in a single string in database, separated with commas
        const chosenDescriptionTags = query.Task.map((taskParam: { From: string; }) => {
            return taskParam.From ? taskParam.From.split(',').map((s: string) => s.trim()) : [];
        }).flat();

        const chosenDescriptionNames = await this.mapDescriptionTagsToNames(chosenDescriptionTags);
        this.DescriptionChosen(chosenDescriptionNames);

        const generalDocCriteria = _.find(query.Documents, (doc) => {
            return doc.Type === generalDocType;
        });

        // Labels, will not be filled if document type is not selected
        this.OnlyDocumentsWithoutLabels(generalDocCriteria ? generalDocCriteria.WithLabels : false);
        if (isNotNullOrUndefined(query.Documents) && isNotNullOrUndefined(generalDocCriteria)) {
            this.LabelChosen(generalDocCriteria.Labels);
            this.updateLabels();
        }

        // Documents
        this.DocsChosenSub.dispose();

        const chosenTypes = _.map(query.Documents, function (docCriteria) {
            return docCriteria.Type;
        });
        this.DocsChosen(chosenTypes);

        // Document general conditions
        // This applies conditions from first document because documents have separate conditions saved although conditions are always the same (in UI they are set per whole filter, not per document)
        if (query.Documents && query.Documents.length > 0) {
            const firstDocument = query.Documents[0];

            this.OnHoldConditionChosen(this.mapDocumentsOnHoldToRadio(firstDocument.OnHoldCondition));
            this.InReviewConditionChosen(this.mapInReviewConditionToRadio(firstDocument.InReviewCondition));
            this.RiskStatusChosen(this.mapRiskStatusChosen(firstDocument.RiskStatusChosen));
        }

        $.when(
            this.updateDocuments()
        ).pipe(() => {

            _.each(query.Documents, (docCriteria) => {
                const doc = this.findDocument(docCriteria.Type);

                if (!doc) {
                    return;
                }

                _.each(docCriteria.Conditions, (condition) => {
                    const field = doc.findDocumentField(condition.Id, condition.InboxFilterConditionName);
                    field.updateField(condition.From, condition.To);
                });
            });

            return true;

        }).always(() => {
            this.initDocsChosenSub();
        });
    }

    findDocument(typeFullName: any) {
        let doc;
        try {
            doc = _.find(this.Documents(), (d) => {
                return d.FullName === typeFullName;
            });
        } catch (e) {
            logger.error("AdvancedSearch findDocument(), type=" + typeFullName);
            logger.error(e);
        }

        return doc || null;
    }

    findTaskField(fieldProperty: any) {
        let field;

        try {
            field = _.find(this.TaskFields(), (t) => {
                return t.Property === fieldProperty;
            });
        } catch (e) {
            logger.error("AdvancedSearch findTaskField(), property=" + fieldProperty);
            logger.error(e);
        }

        return field || null;
    }

    loadAvailableDocuments() {
        $.when(
            get(service, "ConfiguredTypes")
        ).done((configuredTypes) => {
            const types =
                _.chain(configuredTypes)
                    .map((type) => {
                        return {
                            Name: globalization.getPropertyTranslation("#" + type),
                            FullName: type
                        };
                    })
                    .sortBy("Name")
                    .value();

            this.AvailableDocuments(types);

        }).fail(function (jqXhr, textStatus, error) {
            logger.error(error);
        });
    }

    updateLabels() {
        if (this.LabelChosen() !== null) {
            this.SearchedLabels(this.LabelChosen());
        }
    }

    //logic behind this solution is caused by not changing backend API because it's pretty complex one
    //summing up: description with task -> distinct names -> chose description where name is in chosen distinct names
    updateDescriptions() {
        const result: any[] = [];

        if (this.DescriptionChosen() !== null) {
            this.SearchableDescriptions()
                .forEach((description) => {
                    if (this.DescriptionChosen().indexOf(description.Name) !== -1) {
                        result.push(description.Tag);
                    }
                });
            this.DescriptionTaskFields(result);
        }
    }

    updateDocuments() {
        if (!this.DocsChosen()) {
            return $.when();
        }

        const types = _.map(this.DocsChosen(), (chosenType) => {
            // find in previous chosen document types
            let doc = _.find(this.Documents(), (existingType) => {
                return existingType.FullName === chosenType;
            });

            if (doc) {
                return doc;
            }

            // not found - create an instance
            const dc = _.find(this.AvailableDocuments(), (d) => {
                return d.FullName === chosenType;
            });
            const name = (dc) ? dc.Name : null;
            doc = document.create(chosenType, name);

            return $.when(doc.loadConfiguration()).pipe(() => {
                return doc;
            });
        });

        return $.when.apply($, types).pipe((...params: any) => {
            const previousDocuments = this.Documents();
            const toRemoveDocuments = _.reject(previousDocuments,
                (prevDoc) => {
                    return _.some(types,
                        (type) => {
                            return type.FullName === prevDoc.FullName;
                        });
                });
            this.disposeDocuments(toRemoveDocuments);
            this.Documents(params);
            return params;
        });
    }

    disposeDocuments(toRemoveDocuments: any) {
        _.each(toRemoveDocuments,
            (toRemoveDoc) => {
                toRemoveDoc.dispose();
            });
    }

    log() {
        const a: any[] = [];

        _.each(this.Documents(), (d) => {
            const doc = {
                FullName: d.FullName,
                SearchableFields: []
            } as any;
            _.each(d.SearchableFields(), (field) => {
                const f = {
                    Label: field.Label,
                    Values: []
                } as any;
                _.each(field.Values, (val) => {
                    f.Values.push(val);
                });
                doc.SearchableFields.push(f);
            });
            a.push(doc);
        });

        return a;
    }

    /**
    * Resets all search conditions and resets state of Advanced Search object
    */
    reset() {
        this.disposeDocuments(this.Documents());
        this.isAdvancedFilterActive(false);
        this.SavedSearch(null);
        this.Name(null);
        this.Documents([]);
        this.DocsChosen([]);
        this.OnlyDocumentsWithoutLabels(false);
        this.LabelChosen([]);
        this.TaskFields([]);
        this.DescriptionChosen([]);
        this.InReviewChosen(false);
        this.RiskStatusChosen(this.AllAvailableRiskTypes());
        this.OnHoldConditionChosen("all");
        this.InReviewConditionChosen("all");
        this.init();
    }

    OnHoldCondition() {
        const onHoldConditionValueMap = {
            all: null,
            onlyOnHold: true,
            onlyNotOnHold: false
        } as any;
        return onHoldConditionValueMap[this.OnHoldConditionChosen()];
    }

    InReviewCondition() {
        const inReviewConditionValueMap = {
            all: null,
            hideInReview: "hideInReview",
            onlyInReview: "onlyInReview",
            onlyAfterReview: "onlyAfterReview"
        } as any;
        return inReviewConditionValueMap[this.InReviewConditionChosen()];
    }

    dispose() {
        this.WithLabels.dispose();
        this.LabelsEnabled.dispose();
        this.UniqueTaskNames.dispose();
        this.fraudModuleUnsubscribe();
    }
}

export function create() {
    return new Advanced();
} 